// Utilities
import baseAxios from './base'

const ProjectApi = {
  // Get all of a user's projects
  list: params => {
    return baseAxios.get('/projects', { params: params })
  },

  // Create a new project
  create: params => {
    return baseAxios.post('/projects', formDataFromParams(params))
  },

  // Delete a project
  delete: (project_id, name) => {
    return baseAxios.delete(`/projects/${project_id}`, { data: { name: name } })
  },

  // Update a project
  update: (project_id, params) => {
    return baseAxios.put(`/projects/${project_id}`, formDataFromParams(params))
  },

  // Leave a project
  leave: (project_id, params) => {
    return baseAxios.post(`/projects/${project_id}/leave`, params)
  },
}

//
// Helpers
//

const formDataFromParams = params => {
  let formData = new FormData()
  // Add the fields we care about
  if (params.name !== undefined) formData.append('name', params.name)
  if (params.description !== undefined) formData.append('description', params.description)
  if (params.status !== undefined) formData.append('status', params.status)

  // TODO:: Ugh this is fuckin ugly. Find a better way to do uploads and purging...
  // If we've aleady saved it, just send up the ID so we don't purge it
  if (params.removeIcon === true) {
    formData.append('remove_icon', true)
  }
  if (params.icon && !params.icon.id) {
    formData.append('icon', params.icon)
  }

  return formData
}

export default ProjectApi
