import React from 'react'
// Utilities
import _ from 'lodash'
// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const FileList = ({ files, onRemove }) => {
  return !_.isEmpty(files) ? (
    <div className="my-2 p-4 bg-gray-100 border border-gray-200 rounded">
      {files.map(file => (
        <div
          key={file.name || file.meta.name}
          className="flex items-center justify-center p-2 px-4 mt-2 first-child:mt-0 bg-white border border-gray-400 rounded shadow"
        >
          <FontAwesomeIcon icon="file-image" className="mr-4" />
          <p className="flex-1 text-gray-800 py-1 pr-4 truncate">{file.name || file.meta.name}</p>
          <div>
            <button onClick={() => onRemove(file)}>
              <FontAwesomeIcon icon="times" className="text-gray-400 hover:text-gray-800" />
            </button>
          </div>
        </div>
      ))}
    </div>
  ) : null
}

export default FileList
