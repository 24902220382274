import React, { useContext } from 'react'
// Utilities
import { withRouter } from 'react-router-dom'
// Contexts
import ModalContext from 'contexts/ModalContext'

const ModalConductor = ({ location }) => {
  const { activeModal } = useContext(ModalContext)
  // Find our modal component
  const ModalComponent = activeModal ? activeModal.component : null

  return activeModal ? <ModalComponent {...activeModal.props} /> : null
}

export default withRouter(ModalConductor)
