//
// Custom Hook: useWindowScroll
// - Used to fire a callback when the window scrolls
//

import { useCallback, useLayoutEffect } from 'react'
import _ from 'lodash'

const useWindowScroll = (callback, active = true) => {
  // Make sure we only fire this every 150ms
  const throttledCallback = useCallback(_.throttle(callback, 150), [callback])

  useLayoutEffect(() => {
    if (!active) {
      return
    }
    window.addEventListener('scroll', throttledCallback, true)
    return () => {
      window.removeEventListener('scroll', throttledCallback, true)
    }
  }, [throttledCallback, active])
}

export default useWindowScroll
