import React, { useEffect, useState } from 'react'
// Utilities
import { useDropzone } from 'react-dropzone'
// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const FieldUpload = ({ initialFile, onChange, onRemove, placeholder }) => {
  const [file, setFile] = useState(initialFile)

  // Setup our dropzone
  const { getRootProps, getInputProps, inputRef } = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop: acceptedFiles => {
      console.log({ onDrop: acceptedFiles })
      const file = acceptedFiles[0]
      if (!file) {
        return
      }
      // If we require one item, just replace it
      setFile(
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    },
  })

  const onClickOpen = () => {
    inputRef.current.click()
  }

  // Watch for changes to our file and fire an event
  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    file && URL.revokeObjectURL(file.preview)
    // Just return our active file(s)
    onChange && onChange(file)
  }, [file, onChange])

  // Render Constants
  const renderFile = () => {
    const fileToRender = file
      ? {
          id: file.id,
          name: file.name || file.meta.name,
        }
      : null

    // Remove our file
    const onRemoveFile = event => {
      setFile(null)
      event.preventDefault()
      event.stopPropagation()
      return false
    }

    const previewStyles = () => {
      const url = file.id ? file.sizes.thumb.url : file.preview

      return {
        backgroundImage: `url(${url})`,
        backgroundSize: '3rem',
      }
    }

    return fileToRender ? (
      <div className="flex outline-none">
        <div className="p-4 w-16 bg-gray-100 rounded outline-none bg-center bg-no-repeat" style={previewStyles()}></div>
        <div className="w-full p-4 mx-2 border border-gray-200 rounded outline-none font-bold text-sm">
          {fileToRender.name}
        </div>
        <div className="flex ml-2 justify-center items-center">
          <button
            className="flex items-center justify-center bg-overlay-black w-6 h-6 rounded-full text-white"
            onClick={onRemoveFile}
          >
            <FontAwesomeIcon icon="times" size="xs" />
          </button>
        </div>
      </div>
    ) : (
      <div className="flex outline-none">
        <div className="w-full p-4 border border-gray-400 border-dashed rounded outline-none italic text-gray-400 text-sm">
          Click to select an image...
        </div>
      </div>
    )
  }

  return (
    <div className="max-w-xl" {...getRootProps()} onClick={onClickOpen}>
      <input {...getInputProps()} />
      {renderFile()}
    </div>
  )
}

export default FieldUpload
