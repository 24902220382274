import React from 'react'
// Utilities
import { NavLink } from 'react-router-dom'

const SideNav = ({ items = [] }) => {
  return (
    <div className="w-full md:w-64 mr-8 mb-6">
      <ul>
        {items.map(item => (
          <li key={item.key} className="text-gray-600">
            <NavLink
              to={item.to}
              className={'block w-full mb-2 px-4 py-2 font-bold rounded hover:bg-gray-200'}
              activeClassName="active text-white bg-orange-500 hover:bg-orange-500"
              exact
            >
              {item.label}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default SideNav
