import React from 'react'
// Components
import SideNav from 'components/SideNav'

const AccountNav = () => {
  const navItems = [
    {
      key: 'settings',
      label: 'Account Settings',
      to: '/account',
    },
    {
      key: 'billing',
      label: 'Billing & Plans',
      to: '/billing',
    },
  ]

  return <SideNav items={navItems} />
}

export default AccountNav
