import React, { useContext, useEffect } from 'react'
// Utilities
import { withRouter } from 'react-router-dom'
import { isOwner } from 'utils/helpers/permission'
// Contexts
import ProjectContext from 'contexts/ProjectContext'
import UserContext from 'contexts/UserContext'
import AuthContext from 'contexts/AuthContext'
import ModalContext from 'contexts/ModalContext'
// Components
import ProjectHeader from 'components/ProjectHeader'
import ProjectUserList from 'components/ProjectUserList'
import Header from 'components/elements/Header'
import Button from 'components/elements/Button'

const ProjectUsersPage = ({ history }) => {
  const { current: currentUser } = useContext(AuthContext)
  const { setModalNewInvitation } = useContext(ModalContext)

  const { all: allProjects, current: project } = useContext(ProjectContext)

  // Load in our user functions
  const {
    // State
    all: users,
    isLoaded: isUsersLoaded,
    // Functions
    reset: resetUsers,
    load: loadUsers,
    update: updateUser,
    destroy: deleteUser,
  } = useContext(UserContext)

  // Watch for our project to change and load in more
  useEffect(() => {
    if (!project) {
      return
    }
    // Make sure they're an admin user first
    if (!isOwner(project)) {
      history.replace('/projects')
      return
    }
    // Reset then load in new items
    resetUsers()
    loadUsers()
    // Clear users on unmount
    return () => {
      resetUsers()
    }
  }, [project, resetUsers, loadUsers, history])

  //
  // Render Helpers
  //

  const renderProject = () => {
    return project ? (
      <>
        <div className="flex flex-col py-8 mx-auto md:flex-row">
          <div className="flex-1">
            {users && (
              <ProjectUserList
                project={project}
                updateUser={updateUser}
                deleteUser={deleteUser}
                users={users}
                currentUser={currentUser}
              />
            )}
          </div>
        </div>
      </>
    ) : null
  }

  return (
    <div className="py-16 bg-gray-100">
      <Header projects={allProjects} project={project} />
      <ProjectHeader project={project}>
        {isUsersLoaded && (
          <div className="mx-auto max-w-5xl">
            <Button onClick={() => setModalNewInvitation()} color="orange" text="+ Invite Users" />
          </div>
        )}
      </ProjectHeader>
      <div className="py-4 max-w-6xl mx-auto px-4 xl:px-0">{renderProject()}</div>
    </div>
  )
}

export default withRouter(ProjectUsersPage)
