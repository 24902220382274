// Utilities
import baseAxios from './base'
import _ from 'lodash'

const FeedItemApi = {
  // Get all of a project's feed items
  list: (projectId, params) => {
    return baseAxios.get(`/projects/${projectId}/feed`, { params })
  },

  // Create a new feed item
  create: (projectId, params) => {
    return baseAxios.post(`/projects/${projectId}/feed`, formDataFromParams(params), {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  },

  // Delete a feed item
  delete: (projectId, feedItemId) => {
    return baseAxios.delete(`/projects/${projectId}/feed/${feedItemId}`)
  },

  // Update a feed item
  update: (projectId, feedItemId, params) => {
    return baseAxios.put(`/projects/${projectId}/feed/${feedItemId}`, formDataFromParams(params), {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  },

  // Find a specific feed item
  find: (projectId, feedItemId) => {
    return baseAxios.get(`/projects/${projectId}/feed/${feedItemId}`)
  },
}

//
// Helpers
//

const formDataFromParams = params => {
  let formData = new FormData()

  // Add the fields we care about
  formData.append('title', params.title)
  formData.append('description', params.description)
  formData.append('removed_files', params.removedFiles)
  // Add each file we're sending up
  _.each(params.files, file => {
    formData.append('files[]', file)
  })

  return formData
}

export default FeedItemApi
