import React from 'react'
import clsx from 'clsx'
// Utilities
import ExpandableText from 'components/elements/ExpandableText'
import ProjectNavBar from '../ProjectNavBar'

const ProjectHeader = ({ project, children }) => {
  const [isExpanded, setIsExpanded] = React.useState(false)

  const handleExpandChange = expanded => {
    setIsExpanded(expanded)
  }

  return project ? (
    <div className="w-full border-b border-gray-400 bg-white text-left shadow">
      <div
        className={clsx(
          'container mx-auto max-w-6xl flex flex-col text-center md:flex-row md:text-left py-6 px-4 xl:px-0',
          !isExpanded && 'items-center'
        )}
      >
        {project.icon ? (
          <div className="flex items-center justify-center h-28 w-28 rounded md:mr-8">
            <div className="flex justify-center h-28 w-28 bg-cover bg-no-repeat rounded overflow-hidden">
              <img className="h-full max-w-none" src={project.icon.sizes.thumb.url} alt={project.icon.meta.name} />
            </div>
          </div>
        ) : null}
        <div className={clsx('flex flex-1 flex-col justify-center')}>
          <h1 className="text-xl font-bold">{project.name}</h1>
          <h2 className="text-gray-600">
            <ExpandableText text={project.description} onExpandChange={handleExpandChange} />
          </h2>
        </div>
      </div>
      <ProjectNavBar project={project}>{children}</ProjectNavBar>
    </div>
  ) : null
}

export default ProjectHeader
