import React from 'react'

const ExpandableText = ({ text, limit = 256, onExpandChange }) => {
  const [isShowingMore, setIsShowingMore] = React.useState(false)
  const hasTooMuchText = text.length > limit
  const visibleText = isShowingMore || !hasTooMuchText ? text : `${text.slice(0, limit)}...`

  const handleShowMore = event => {
    const nextVal = !isShowingMore
    setIsShowingMore(nextVal)
    onExpandChange && onExpandChange(nextVal)

    event.stopPropagation()
    event.preventDefault()
    return false
  }

  const renderButton = () => {
    return (
      <button className="pl-1 underline focus:outline-none" onClick={handleShowMore}>
        {isShowingMore ? 'Show Less' : 'Show More'}
      </button>
    )
  }

  return (
    <>
      {visibleText}
      {hasTooMuchText && renderButton()}
    </>
  )
}

export default ExpandableText
