import React, { useCallback, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
// Utilities
import { Link, withRouter } from 'react-router-dom'
import _ from 'lodash'
// Components
import Button from 'components/elements/Button'
import useDeepCompareEffect from 'use-deep-compare-effect'
import useWindowScroll from 'utils/hooks/useWindowScroll'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Dropdown = ({
  options,
  omitOptions = [],
  selected,
  onChange,
  align,
  buttonProps,
  disabled,
  extraClasses,
  payload = {},
  selectable,
  isForm,
}) => {
  const dropdownNode = useRef()
  const [open, setOpen] = useState(false)
  const [selectedOptionId, setSelectedOption] = useState(selected)

  const closeDropdown = useCallback(
    event => {
      if (!open || dropdownNode.current.contains(event.target)) {
        // We clicked inside the dropdown
        return
      }
      // Now set us as
      setOpen(false)
    },
    [open]
  )

  // Watch for our page to scroll to close dropdowns
  useWindowScroll(closeDropdown, open)

  // Watch for clicks outside this dropdown
  useEffect(() => {
    document.addEventListener('mousedown', closeDropdown)
    return () => {
      document.removeEventListener('mousedown', closeDropdown)
    }
  }, [closeDropdown])

  // Watch for changes to our selected option
  useEffect(() => {
    setSelectedOption(selected)
  }, [selected])

  // Store our currently active option since selectedOptionId
  // is just the id of the selected value
  const activeOption = _.find(options, { id: selectedOptionId })

  // NOTE: We are using a deep compare effect here specifically
  // because the payload (object) we're passing through to change & click events
  // is an object (which will cuase useEffect to think it's new each time)

  useDeepCompareEffect(() => {
    if (disabled) {
      return
    }
    // Don't fire if we haven't changed
    if (_.isEqual(selectedOptionId, selected)) {
      return
    }
    // Fire our change callback
    onChange && onChange(selectedOptionId, payload)
    // Close the dropdown now :)
    setOpen(false)
  }, [selectedOptionId, selected, disabled, onChange, payload])

  //
  // Render Helpers
  //

  const renderOptions = () => {
    return (options || [])
      .filter(opt => omitOptions.indexOf(opt) === -1)
      .map((option, index) => {
        const OptionTag = option.to ? Link : 'button'
        const active = option.id === selectedOptionId
        const classes = [`block w-full p-4 ${option.extraClasses}`]
        // Add some selected classes
        if (active) {
          classes.push('text-gray-400 cursor-default hover:bg-white')
        }

        const onClick = event => {
          if (option.to) {
            return
          }
          // Stop forms from submitting
          event.preventDefault()
          event.stopPropagation()
          // If we can't actually change this... don't change it
          if (!disabled && !active) {
            selectable && setSelectedOption(option.id)
            // Now fire our click event
            option.onClick && option.onClick(event, payload)
          }
          // Close the dropdown now :)
          setOpen(false)
        }

        return (
          <li key={index} className="w-full border-gray-200 border-t first-child:border-t-0 hover:bg-gray-100">
            <OptionTag
              className={clsx(classes, 'flex items-center text-left text-sm font-bold')}
              onClick={onClick}
              to={option.to}
            >
              {option.icon && <FontAwesomeIcon icon={option.icon} className="mr-4" />}
              <span>{option.text}</span>
            </OptionTag>
          </li>
        )
      })
  }

  // Some custom classes to position the dropdown
  const dropdownClasses = [
    'w-auto',
    'min-w-full',
    'mt-1',
    'z-10',
    'max-h-16',
    'absolute',
    'bg-white',
    'border',
    'border-gray-400',
    'shadow-lg',
    'rounded',
    'overflow-hidden',
    'overflow-y-auto',
    ...(align === 'left' ? ['left-0', 'right-auto'] : ['right-0', 'left-auto']),
  ].join(' ')

  // Force some button props if we're using this as a selection dropdown
  const activeButtonProps = {
    ...buttonProps,
    ...(selectable && selectedOptionId
      ? {
          text: activeOption ? activeOption.text : '',
          icon: activeOption ? activeOption.icon : null,
        }
      : {}),
  }

  const toggleOpen = event => {
    if (disabled) {
      return
    }
    // Open options
    setOpen(!open)
    event.preventDefault()
    event.stopPropagation()
  }

  return (
    <div className={clsx('relative', extraClasses)} ref={dropdownNode}>
      <Button {...activeButtonProps} onClick={toggleOpen} disabled={disabled} />
      {open && (
        <div className={dropdownClasses}>
          <ul className="min-w-64">{renderOptions()}</ul>
        </div>
      )}
    </div>
  )
}

export default withRouter(Dropdown)
