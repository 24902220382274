import React, { useContext, useEffect } from 'react'
// Utilities
import { Redirect, Switch, withRouter } from 'react-router-dom'
// Contexts
import ProjectContext from 'contexts/ProjectContext'
// Components
import PrivateRoute from 'components/extras/PrivateRoute'
// Pages
import ProjectsPage from 'pages/ProjectsPage'
import ProjectFeedPage from 'pages/ProjectFeedPage'
import ProjectUsersPage from 'pages/ProjectUsersPage'
import AccountPage from 'pages/AccountPage'
import BillingPage from 'pages/BillingPage'

//
// Render Helpers
//

const AuthenticatedRoutes = ({ location }) => {
  const { isLoaded: isProjectsLoaded, load: loadProjects } = useContext(ProjectContext)

  // Load in our projects on load
  useEffect(() => {
    if (isProjectsLoaded) {
      return
    }
    // Parse our location search for any params
    const params = {}
    const queryString = require('query-string')
    const query = queryString.parse(location.search)
    // Add our token if we have one
    if (query.invite) {
      params['invite_token'] = query.invite
    }

    loadProjects(params)
  }, [loadProjects, location.search, isProjectsLoaded])

  return (
    <Switch>
      <PrivateRoute path="/account" component={AccountPage} />
      <PrivateRoute path="/billing" component={BillingPage} />
      <PrivateRoute path="/projects/:projectId/users" component={ProjectUsersPage} />
      <PrivateRoute path="/projects/:projectId/:feedItemId" component={ProjectFeedPage} />
      <PrivateRoute path="/projects/:projectId" component={ProjectFeedPage} />
      <PrivateRoute path="/projects" component={ProjectsPage} />
      <Redirect to={{ pathname: '/projects' }} />
    </Switch>
  )
}

export default withRouter(AuthenticatedRoutes)
