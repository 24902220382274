import React, { useCallback, useContext, useState } from 'react'
// Utilities
import _ from 'lodash'
import { withRouter } from 'react-router-dom'
// Contexts
import UserContext from 'contexts/UserContext'
import ModalContext from 'contexts/ModalContext'
// Components
import BaseModal from 'components/modals/BaseModal'
import FieldMultiTag from 'components/elements/FieldMultiTag'

const NewInvitationModal = () => {
  const { create: createUsers } = useContext(UserContext)
  const { setModalNone } = useContext(ModalContext)
  const [emails, setEmails] = useState([])
  const [message, setMessage] = useState([])
  const [formErrors, setFormErrors] = useState({})
  const [submittingForm, setSubmittingForm] = useState(false)

  const updateEmails = useCallback(tags => {
    setEmails(tags)
  }, [])

  //
  // Form Validations
  //

  const validateForm = () => {
    return new Promise((resolve, reject) => {
      const errors = []

      // Make sure we actually validate :)
      if (_.isEmpty(emails)) {
        // Invalid
        errors.push({
          parameter: 'emails',
          message: 'Please enter at least one valid email',
        })
      }

      // Resolve or reject based on errors
      _.isEmpty(errors) ? resolve() : reject(errors)
    })
  }

  const renderError = (type, classes = '') => {
    // Grab the error from our errors object
    const error = formErrors[type]
    const classNames = `py-2 px-4 mt-2 bg-red-400 text-white text-left text-sm rounded ${classes}`

    return error ? <div className={classNames}>{error.message}</div> : null
  }

  //
  // Event Handlers
  //

  const onSubmit = event => {
    event.preventDefault()
    // Validate our form
    validateForm()
      .then(() => {
        // We're good to submit!
        setFormErrors({})
        setSubmittingForm(true)
        // We're creating a new project
        // Send the ajax request
        createUsers({
          emails: emails,
          message: message,
        })
          .then(response => {
            // Redirect us to the right place!
            setModalNone()
          })
          .catch(error => {
            // Store the form errors we got back
            setFormErrors(_.keyBy(error.response.data, 'parameter'))
            setSubmittingForm(false)
          })
      })
      .catch(errors => {
        // We are not ready to submit yet
        setFormErrors(_.keyBy(errors, 'parameter'))
        setSubmittingForm(false)
      })
  }

  //
  // Render Constants
  //

  return (
    <BaseModal
      title="Send Invitations"
      submitProps={{
        text: submittingForm ? 'Sending...' : 'Send Invites',
        size: 'large',
        color: 'orange',
        extraClasses: 'mr-4',
      }}
      isSubmitting={submittingForm}
      onSubmit={onSubmit}
    >
      <FieldMultiTag onChange={updateEmails} placeholder="Enter email addresses" />
      {renderError('emails')}
      <textarea
        className="w-full mt-4 p-4 h-24 border shadow-inner rounded bg-gray-100 focus:bg-white outline-none focus:shadow-outline"
        onChange={event => setMessage(event.target.value)}
        value={message}
        placeholder="Custom invite message (optional)"
        disabled={submittingForm}
      />
    </BaseModal>
  )
}

export default withRouter(NewInvitationModal)
