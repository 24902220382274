import React from 'react'
// Utilities
import moment from 'moment'
import _ from 'lodash'
// Components
import ProjectFeedGroup from 'components/ProjectFeedGroup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ProjectFeed = ({ project, items, loaded, setModalEditFeedItem }) => {
  // Group our items by the day they were posted
  const groupedItems = () => {
    if (_.isEmpty(items)) {
      return {}
    }

    // Helper method for formatting date
    const formatDate = createdAt => {
      const date = moment(createdAt)

      return {
        key: date.format('M[-]D[-]YYY'),
        label: date.format('MMMM D'),
        timeLabel: date.format('MM[/]D[/]YY h[:]mm A'),
      }
    }

    // Loop through the items and group them by date
    return items.reduce((groups, item) => {
      // Format our date object
      const date = formatDate(item.createdAt)
      // Create a new date object to hold each day's items
      groups[date.key] = groups[date.key]
        ? groups[date.key]
        : {
            id: date.key,
            label: date.label,
            items: [],
          }
      // Add in this item but make sure we append the current localDate as well
      groups[date.key].items.push({
        ...item,
        localDate: date,
      })
      return groups
    }, {})
  }

  // Render Functions

  const renderGroups = () => {
    if (!project || _.isEmpty(items)) {
      return
    }

    // Grab the values from our grouped days object
    const days = Object.values(groupedItems())

    return days.map(day => <ProjectFeedGroup key={day.id} project={project} label={day.label} items={day.items} />)
  }

  const renderItems = () => {
    let itemViews

    if (!loaded) {
      itemViews = (
        <div className="text-center">
          <FontAwesomeIcon icon="spinner" size="2x" color="#ed8936" className="opacity-50" pulse />
        </div>
      )
    } else if (_.isEmpty(items)) {
      itemViews = (
        <div className="p-16 text-center text-gray-400">
          <div>You have no status updates!</div>
        </div>
      )
    } else {
      itemViews = <div>{renderGroups()}</div>
    }

    return itemViews
  }

  return project ? <div className="flex-1">{renderItems()}</div> : null
}

export default ProjectFeed
