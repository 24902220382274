import React, { useContext, useState } from 'react'
// Utilities
import { withRouter } from 'react-router-dom'
// Contexts
import ModalContext from 'contexts/ModalContext'
import FeedItemContext from 'contexts/FeedItemContext'
// Components
import BaseModal from 'components/modals/BaseModal'

const DeleteFeedItemModal = ({ project, item }) => {
  const { setModalNone } = useContext(ModalContext)
  const { destroy: deleteFeedItem } = useContext(FeedItemContext)
  const [submittingForm, setSubmittingForm] = useState(false)

  //
  // Event Handlers
  //

  const onSubmit = event => {
    event.preventDefault()
    // We're good to submit!
    setSubmittingForm(true)
    // Send the ajax request
    deleteFeedItem(item.id)
      .then(response => {
        // Force-redirect us to the projects page if we are on the now-deleted project
        setModalNone()
      })
      .catch(error => {
        // Store the form errors we got back
        setSubmittingForm(false)
      })
  }

  //
  // Render Constants
  //

  return (
    <BaseModal
      title={'Are you sure you want to delete this item?'}
      submitProps={{
        text: submittingForm ? 'Deleting...' : 'Delete',
        color: 'red',
        extraClasses: 'mr-4',
      }}
      cancelProps={{
        size: 'normal',
        extraClasses: 'mr-4',
      }}
      isSubmitting={submittingForm}
      onSubmit={onSubmit}
    >
      <p className="text-gray-800">
        Deleting an item from your feed cannot be undone. <strong>Any attached files will also be removed.</strong>
      </p>
    </BaseModal>
  )
}

export default withRouter(DeleteFeedItemModal)
