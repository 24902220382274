import React from 'react'
import { createUseStyles } from 'react-jss'
import clsx from 'clsx'
import { isOwner } from 'utils/helpers/permission'
// Utilities
import { NavLink, withRouter } from 'react-router-dom'

// Styles

const useStyles = createUseStyles({
  navButton: {
    '&:not(.active)': {
      '& span': {
        backgroundColor: '#b2b2b2',
      },
      '&:hover': {
        borderBottomColor: '#cbd5e0', // border-gray-400
      },
    },
  },
})

// Component

const ProjectNavBar = ({ project, children }) => {
  const classes = useStyles()

  const links = [
    {
      key: 'status_updates',
      label: 'Status Updates',
      to: `/projects/${project.id}`,
    },
  ]

  if (isOwner(project)) {
    links.push({
      key: 'users',
      label: 'Users',
      to: `/projects/${project.id}/users`,
    })
  }

  return (
    <div className="h-16 border-t border-gray-200">
      <div className="flex container mx-auto max-w-6xl px-4 xl:px-0">
        <div className="flex flex-1 items-center h-full text-gray-500 text-sm">
          {links.map(link => (
            <NavLink
              key={link.key}
              className={clsx(
                classes.navButton,
                'flex items-center justify-center h-16 px-4 border-b-2 border-transparent font-bold'
              )}
              activeClassName="active text-orange-500 border-orange-500"
              to={link.to}
              exact
            >
              {link.count && (
                <span className="px-2 mr-2 bg-orange-500 text-white font-bold rounded-full">{link.count}</span>
              )}
              {link.label}
            </NavLink>
          ))}
        </div>
        <div className="flex items-center">{children}</div>
      </div>
    </div>
  )
}

export default withRouter(ProjectNavBar)
