import React, { createContext, useCallback, useContext, useState } from 'react'
// Contexts
import ProjectContext from 'contexts/ProjectContext'
// Utilities
import _ from 'lodash'
import UserApi from 'utils/api/user'

// Setup our context with initial state
const UserContext = createContext({
  // Data
  all: {
    joined: [],
    invited: [],
  },
  // State
  isLoaded: false,
  totalCount: null,
  projectId: null,
  // Functions
  load: () => {},
  create: () => {},
  update: () => {},
  destroy: () => {},
  reset: () => {},
})

// Build our provider (wrapper)
export const UserProvider = ({ children }) => {
  const { current: project } = useContext(ProjectContext)
  const [all, setAll] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)
  const [totalCount, setTotalCount] = useState(null)
  const [projectId, setProjectId] = useState(null)

  // Load all items
  const load = useCallback(() => {
    if (!project) {
      return
    }

    return UserApi.list(project.id).then(response => {
      setAll(response.data.data)
      // Set our new meta data
      setTotalCount(response.data.meta.total)
      // Tell the UI we've loaded
      setIsLoaded(true)
      // Store this project ID so we know if we need to reset it
      setProjectId(project.id)
      return response
    })
  }, [project])

  // Create a project
  const create = useCallback(
    params => {
      return UserApi.create(project.id, params).then(response => {
        setAll(response.data.data)
        // Set our new meta data
        setTotalCount(response.data.meta.total)
        return response
      })
    },
    [project]
  )

  // Update a project
  const update = useCallback(
    (id, params) => {
      return UserApi.update(project.id, id, params).then(response => {
        setAll(prevAll => {
          const joined = prevAll.joined.slice()
          // Replace a project at a specific index
          const index = _.findIndex(joined, { id: response.data.data.id })
          joined.splice(index, 1, response.data.data)

          return {
            ...prevAll,
            joined: joined,
          }
        })
        return response
      })
    },
    [project]
  )

  // Delete a project
  const destroy = useCallback(
    id => {
      return UserApi.delete(project.id, id).then(response => {
        setAll(prevAll => ({
          ...prevAll,
          joined: _.filter(prevAll.joined, item => item.id !== id),
        }))
        return response
      })
    },
    [project]
  )

  // Reset all of our data and reload
  const reset = useCallback(() => {
    // Reset things
    setIsLoaded(false)
    setAll([])
  }, [])

  return (
    <UserContext.Provider
      value={{
        // Data
        all,
        // State
        isLoaded,
        totalCount,
        projectId,
        // Functions
        load,
        create,
        update,
        destroy,
        reset,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default UserContext
