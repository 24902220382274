//
// Keep track of our user roles!
// - Each user will be given a specific set of features that
// - they are allowed to do within the app
//

const userRoles = {
  owner: {
    id: 'owner',
    value: 'owner',
    text: 'Owner',
    features: [],
  },
  admin: {
    id: 'admin',
    value: 'admin',
    text: 'Admin',
    features: [],
  },
  client: {
    id: 'client',
    value: 'client',
    text: 'Client',
    features: [],
  },
}

export default userRoles
