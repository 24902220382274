import React, { useContext } from 'react'
// Contexts
import ModalContext from 'contexts/ModalContext'
// Utilities
import _ from 'lodash'
import { isOwner } from 'utils/helpers/permission'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ProjectFeedItem = ({ project, item, history }) => {
  const { setModalEditFeedItem, setModalDeleteFeedItem, setModalFile, setModalFeedItem } = useContext(ModalContext)

  // Event Handlers

  const handleClickOpenFile = ({ event, file }) => {
    window.open(file.urls.download, '_blank')
    // Stop the click from continuing
    event.stopPropagation()
    event.preventDefault()
  }

  const onClickEdit = event => {
    setModalEditFeedItem({ project, item })
    // Stop the click from continuing
    event.stopPropagation()
    event.preventDefault()
  }

  const onClickDelete = event => {
    setModalDeleteFeedItem({ project, item })
    // Stop the click from continuing
    event.stopPropagation()
    event.preventDefault()
  }

  const onClickFeedItem = event => {
    history.push(`/projects/${project.id}/${item.id}`)
    setModalFeedItem({ project, item })
    // Stop the click from continuing
    event.stopPropagation()
    event.preventDefault()
  }

  const onClickComment = event => {
    history.push(`/projects/${project.id}/${item.id}`)
    setModalFeedItem({ project, item, scrollToBottom: true })
    // Stop the click from continuing
    event.stopPropagation()
    event.preventDefault()
  }

  // Render Helpers

  const renderImages = () => {
    const imageFiles = (item.files || []).filter(file => file.meta.type === 'image')

    return !_.isEmpty(imageFiles) ? (
      <div className="flex p-4 my-4 bg-gray-100 rounded">
        {imageFiles.map(file =>
          file.sizes ? (
            <div
              key={file.id || file.name || file.meta.name}
              className="flex ml-4 first-child:ml-0 justify-center items-center bg-white h-100px w-auto rounded shadow cursor-pointer hover:shadow-outline"
              onClick={() => setModalFile({ file })}
            >
              <img src={file.sizes.thumb.url} alt={file.meta.name} className="block h-full" />
            </div>
          ) : null
        )}
      </div>
    ) : null
  }

  const renderOtherFiles = () => {
    const otherFiles = (item.files || []).filter(file => file.meta.type === 'file')

    return !_.isEmpty(otherFiles) ? (
      <div className="py-4">
        <p className="inline-block text-sm font-bold mr-2">Attachments: </p>
        {otherFiles.map(file => (
          <button
            key={file.id || file.name || file.meta.name}
            onClick={event => handleClickOpenFile({ event, file })}
            className="inline-block text-sm underline cursor text-orange-500 hover:text-orange-400 mr-2"
          >
            <FontAwesomeIcon icon="file" />
            <span className="ml-2">{file.meta.name}</span>
          </button>
        ))}
      </div>
    ) : null
  }

  return (
    <div
      className="h-auto mx-auto mt-4 first-child:mt-0 p-4 cursor-pointer bg-white hover:shadow-outline-orange border border-gray-300 shadow rounded"
      onClick={onClickFeedItem}
    >
      <div className="flex items-center">
        <div className="flex flex-1 pr-4">
          <p className="font-bold text-2xl text-gray-900">{item.title}</p>
        </div>
      </div>

      {item.description && <p className="text-gray-800 py-1">{item.description}</p>}

      {renderImages()}
      {renderOtherFiles()}

      <div className="pt-2">
        <time className="text-sm text-gray-600" dateTime={item.createdAt}>
          {item.localDate.timeLabel}
        </time>
        {isOwner(project) && (
          <>
            <button className="text-sm text-gray-600 hover:text-yellow-600 underline ml-2" onClick={onClickEdit}>
              Edit
            </button>
            <button className="text-sm text-gray-600 hover:text-red-600 underline ml-2" onClick={onClickDelete}>
              Delete
            </button>
          </>
        )}
        {item.commentCount > 0 && (
          <button className="text-sm text-gray-600 hover:text-gray-800 underline ml-2" onClick={onClickComment}>
            {item.commentCount} {item.commentCount === 1 ? 'Comment' : 'Comments'}
          </button>
        )}
      </div>
    </div>
  )
}

export default withRouter(ProjectFeedItem)
