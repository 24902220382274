import React, { useContext, useEffect, useRef, useState } from 'react'
// Utilities
import { withRouter } from 'react-router-dom'
import clsx from 'clsx'
import _ from 'lodash'
import moment from 'moment'
import CommentApi from 'utils/api/comments'
// Contexts
import AuthContext from 'contexts/AuthContext'
import ModalContext from 'contexts/ModalContext'
import FeedItemContext from 'contexts/FeedItemContext'
// Components
import Button from 'components/elements/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const FeedItemModal = ({ project, itemId, item, scrollToBottom, history }) => {
  // Field Refs
  const commentFieldRef = useRef()
  const scrollRef = useRef()
  // Contexts
  const { current: currentUser } = useContext(AuthContext)
  const { setModalNone } = useContext(ModalContext)
  const { findById: findFeedItemById, updateLocal: updateLocalItem } = useContext(FeedItemContext)
  // State helpers
  const [isExpanded, setIsExpanded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [loadedItem, setLoadedItem] = useState(item)
  const [comments, setComments] = useState([])
  const [commentMessage, setCommentMessage] = useState('')
  const [activeFileIndex, setActiveFileIndex] = useState(null)
  const [commentFocused, setCommentFocused] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  // Helper methods

  const formatDate = createdAt => {
    const date = moment(createdAt)

    return {
      key: date.format('M[-]D[-]YYY'),
      label: date.format('MMMM D'),
      timeLabel: date.format('MM[/]D[/]YY h[:]mm A'),
    }
  }

  const scrollToEnd = () => {
    // Scroll to the bottom
    setTimeout(() => {
      scrollRef.current.scrollTo({
        top: 99999,
        behavior: 'smooth',
      })
    }, 50)
  }

  const handleClickOpenFile = ({ event, file }) => {
    window.open(file.urls.download, '_blank')
    // Stop the click from continuing
    event.stopPropagation()
    event.preventDefault()
  }

  // Lifecycle Events

  // When it's opened, set a body class so we don't scroll the body
  useEffect(() => {
    document.body.classList.add('overflow-hidden')
    return () => {
      document.body.classList.remove('overflow-hidden')
    }
  }, [])

  // When it's opened, set a body class so we don't scroll the body
  useEffect(() => {
    if (!loadedItem) return
    //set active index as the first image
    const imageIndex = loadedItem.files.findIndex(file => file.meta.type === 'image')
    setActiveFileIndex(imageIndex !== -1 ? imageIndex : null)
  }, [loadedItem])

  // When it's opened, see if we have an item id to load in
  useEffect(() => {
    if (loadedItem || !itemId) {
      return
    }
    // Make sure we only load the data in once
    if (isLoading) {
      return
    }
    setIsLoading(true)
    // Grab our cached item or go get it from the API
    findFeedItemById(itemId).then(response => {
      // Update our main item
      setLoadedItem({
        ...response.data,
        localDate: formatDate(response.data.createdAt),
      })
      // We're no longer loading!
      setIsLoading(false)
    })
  }, [isLoading, findFeedItemById, itemId, loadedItem])

  // Once we have a loaded item, load the comments
  useEffect(() => {
    // Load in our comments
    if (!project || !loadedItem) {
      return
    }
    // Create the comment
    CommentApi.list(project.id, loadedItem.id).then(response => {
      // Update our comments
      setComments(
        response.data.map(c => ({
          ...c,
          localDate: formatDate(c.createdAt),
        }))
      )
      // Scroll us to the bottom now if we want to
      scrollToBottom && scrollToEnd()
    })
  }, [project, loadedItem, scrollToBottom])

  // Event Handlers

  const onClickClose = event => {
    event.stopPropagation()
    setModalNone()
    // Route us back to the standard feed page
    history.push(`/projects/${project.id}`)
  }

  const onFocusComment = () => {
    // Update the UI
    setCommentFocused(true)
    // Scroll to the end of the modal
    scrollToEnd()
  }

  const onSubmitComment = event => {
    if (event) {
      event.stopPropagation()
      event.preventDefault()
    }
    // Grab our field value
    const value = commentFieldRef.current.value
    if (_.isEmpty(value)) {
      return
    }
    // Update our UI
    setIsSubmitting(true)
    // Create the comment
    CommentApi.create(project.id, loadedItem.id, { message: value })
      .then(response => {
        const addedComment = response.data
        // Update our comments
        setComments(prevComments => [
          ...prevComments,
          Object.assign({}, addedComment, {
            localDate: formatDate(addedComment.createdAt),
          }),
        ])
        // Clear our message
        setCommentMessage('')
        commentFieldRef.current.value = ''
        // Scroll to the bottom
        scrollToEnd()
        // Update our UI
        setIsSubmitting(false)
        // Update our local item as well so other pages display it
        const newItem = _.cloneDeep(loadedItem)
        newItem.commentCount += 1
        updateLocalItem(newItem)
      })
      .catch(() => {
        // Update our UI
        setIsSubmitting(false)
      })
  }

  const onCancelComment = () => {
    // Clear our message
    setCommentMessage('')
    // Blur our field
    commentFieldRef.current.blur()
  }

  const onClickDeleteComment = id => {
    // Delete the comment
    CommentApi.delete(project.id, loadedItem.id, id).then(response => {
      // Update our comments to remove that one
      setComments(prevComments => _.filter(prevComments, c => c.id !== parseInt(id)))
      // Update our local item as well so other pages display it
      const newItem = _.cloneDeep(loadedItem)
      newItem.commentCount -= 1
      updateLocalItem(newItem)
    })
  }

  const onKeyDownComment = event => {
    const sendKeys = [13] // [Space, Enter, Tab]
    // If we send up one of these keys, submit our text
    if (sendKeys.indexOf(event.keyCode) !== -1) {
      onSubmitComment()
      // Blur our field
      commentFieldRef.current.blur()
    }
  }

  // Render helpers

  // Render our photo blocks (selectable items)
  const renderSelectablePhotos = () => {
    const imageFiles = (loadedItem.files || []).filter(file => file.meta.type === 'image')

    return !_.isEmpty(imageFiles) ? (
      <div className="flex justify-center items-center p-4 bg-black">
        {(loadedItem.files || []).map((file, index) =>
          file.meta.type === 'image' ? (
            <div
              key={file.name || file.meta.name}
              className={clsx(
                'flex ml-4 first-child:ml-0 justify-center items-center h-16 w-auto bg-white border-2 border-transparent shadow cursor-pointer hover:shadow-outline-orange opacity-50',
                index === activeFileIndex ? 'border-white opacity-100' : null
              )}
              onClick={() => setActiveFileIndex(index)}
            >
              <img src={file.sizes.thumb.url} alt={file.meta.name} className="block h-full" />
            </div>
          ) : null
        )}
      </div>
    ) : null
  }

  // Render our actual file section
  const renderFiles = () =>
    !_.isEmpty(loadedItem.files) ? (
      <div className="my-2">
        <p className="inline-block text-sm font-bold mr-2 mb-2">Attachments: </p>
        {loadedItem.files.map((file, index) => (
          <div
            key={file.name || file.meta.name}
            className="text-sm font-bold mr-2 mb-2 text-orange-500 hover:text-orange-400 cursor-pointer"
            onClick={event => handleClickOpenFile({ event, file })}
          >
            <FontAwesomeIcon icon={file.meta.type === 'image' ? 'file-image' : 'file'} className="mr-2 inline-block" />
            <p className="inline-block">{file.meta.name}</p>
          </div>
        ))}
      </div>
    ) : null

  // Render the photo section
  const renderPhotoSection = () => {
    const imageFiles = (loadedItem.files || []).filter(file => file.meta.type === 'image')
    const activeFile = loadedItem.files[activeFileIndex]

    return activeFile && imageFiles.length > 0 ? (
      <div>
        <div className={clsx(isExpanded && 'flex flex-col h-screen')}>
          <div className="flex items-center h-12 px-4 bg-black md:rounded-tr md:rounded-tl">
            <div className="w-16">{activeFile.meta.name}</div>
            <div className="flex-1 text-white text-sm font-bold text-center">
              <span className="hidden md:block">{activeFile.meta.name}</span>
            </div>
            <div className="flex justify-end items-center w-16">
              <button className="hidden text-white md:block" onClick={() => setIsExpanded(!isExpanded)}>
                <FontAwesomeIcon icon={isExpanded ? 'times' : 'expand'} size="lg" />
              </button>
              <button className="text-white md:hidden" onClick={onClickClose}>
                <FontAwesomeIcon icon="times" size="lg" />
              </button>
            </div>
          </div>
          <div className={clsx('pt-4 bg-black', !isExpanded ? 'h-100' : 'flex-1 overflow-scroll')}>
            <img
              src={!isExpanded ? activeFile.sizes.medium.url : activeFile.sizes.large.url}
              alt={activeFile.meta.name}
              className={clsx('block m-auto', !isExpanded && 'h-full')}
            />
          </div>
          {renderSelectablePhotos()}
        </div>
      </div>
    ) : null
  }

  // Render our actual item title/Description
  const renderItemDetails = () => (
    <>
      <div className="flex items-start h-auto p-4">
        <div>
          <div className="flex items-center justify-center h-12 w-12 mr-4 bg-gray-800 rounded-full">
            {loadedItem.user.icon ? (
              <div
                className="h-12 w-12 bg-gray-100 bg-cover bg-no-repeat rounded-full"
                style={{ backgroundImage: `url(${loadedItem.user.icon.sizes.thumb.url})` }}
              ></div>
            ) : null}
          </div>
        </div>
        <div className="flex-1">
          <p className="font-bold">{loadedItem.title}</p>
          {loadedItem.description && <p className="text-gray-800 py-1">{loadedItem.description}</p>}
          <time className="text-sm text-gray-600" dateTime={loadedItem.createdAt}>
            {loadedItem.localDate.timeLabel}
          </time>
        </div>
      </div>
      <div className="px-4 md:pl-20">{renderFiles()}</div>
    </>
  )

  // Comments section
  const renderComments = () => {
    return comments && comments.length > 0 ? (
      <div className="flex-1 px-4">
        {comments.map(comment => (
          <div key={comment.id} className="flex items-start p-4 border-t border-gray-100 first-child:border-t-0">
            <div>
              <div className="h-8 w-8 mr-4 bg-gray-800 rounded-full">
                {comment.user.icon ? (
                  <div
                    className="h-8 w-8 bg-gray-100 bg-cover bg-no-repeat rounded-full"
                    style={{ backgroundImage: `url(${comment.user.icon.sizes.thumb.url})` }}
                  ></div>
                ) : null}
              </div>
            </div>
            <div className="flex-1">
              <p className="text-gray-800">{comment.message}</p>
              <p className="pt-2 text-sm text-gray-600">
                <span>{comment.user.displayName}</span>
                <time className="pl-2 text-gray-600" dateTime={comment.createdAt}>
                  {comment.localDate.timeLabel}
                </time>
                {comment.user.id === currentUser.id && (
                  <>
                    <button
                      className="hover:text-red-600 underline ml-2"
                      onClick={() => onClickDeleteComment(comment.id)}
                    >
                      Delete
                    </button>
                  </>
                )}
              </p>
            </div>
          </div>
        ))}
      </div>
    ) : null
  }

  // Render our new comment field
  const renderNewComment = () => {
    const expandedComment = commentFocused || commentMessage || isSubmitting

    return (
      <div className="p-4 border-t border-gray-200">
        <form onSubmit={onSubmitComment}>
          <textarea
            ref={commentFieldRef}
            className={clsx(
              'w-full px-4 py-2 h-10 border shadow-inner text-sm align-top rounded bg-gray-100 focus:bg-white outline-none focus:shadow-outline',
              expandedComment ? 'h-24' : null
            )}
            onFocus={onFocusComment}
            onBlur={() => setCommentFocused(false)}
            onChange={event => setCommentMessage(event.target.value)}
            onKeyDown={onKeyDownComment}
            placeholder="Leave a comment..."
          />
          {expandedComment && (
            <div className="mt-2 flex items-center">
              <Button
                text="Leave Comment"
                type="submit"
                color="orange"
                extraClasses="inline-block mr-2"
                disabled={!commentMessage || isSubmitting}
              />
              <Button
                text="Cancel"
                color="gray"
                extraClasses="inline-block"
                disabled={isSubmitting}
                onClick={onCancelComment}
              />
            </div>
          )}
        </form>
      </div>
    )
  }

  // Render our loaded state
  const renderLoadedItem = () => {
    return (
      <div className="flex flex-col">
        <div className="flex-1 pb-4">
          {loadedItem.files.length === 0 && (
            <div className="flex justify-end items-center h-16 bg-white border-b border-gray-200 md:hidden">
              <button
                className="flex justify-center items-center h-16 w-16 text-gray-900 md:hidden"
                onClick={onClickClose}
              >
                <FontAwesomeIcon icon="times" size="lg" />
              </button>
            </div>
          )}
          {renderPhotoSection()}
          {!isExpanded && (
            <>
              {renderItemDetails()}
              {renderComments()}
            </>
          )}
        </div>
        {!isExpanded && renderNewComment()}
      </div>
    )
  }

  // Render our loading state
  const renderLoading = (
    <div className="flex justify-center items-center py-32">
      <FontAwesomeIcon icon="spinner" size="2x" color="#ed8936" className="opacity-50" pulse />
    </div>
  )

  return (
    <div className="fixed z-50 top-0 left-0 bottom-0 right-0 min-h-screen bg-overlay-black" onClick={onClickClose}>
      <div
        ref={scrollRef}
        className={clsx(
          'w-full h-screen bg-white overflow-scroll md:bg-transparent pb-32',
          !isExpanded && 'md:pb-8 md:p-8'
        )}
      >
        <div
          className={clsx('w-full m-auto bg-white md:rounded', !isExpanded && 'max-w-3xl')}
          onClick={event => event.stopPropagation()}
        >
          {loadedItem ? renderLoadedItem() : renderLoading}
        </div>
      </div>
    </div>
  )
}

export default withRouter(FeedItemModal)
