import React, { createContext, useState } from 'react'
// Components
import NewProjectModal from 'components/modals/NewProjectModal'
import EditProjectModal from 'components/modals/EditProjectModal'
import DeleteProjectModal from 'components/modals/DeleteProjectModal'
import NewFeedItemModal from 'components/modals/NewFeedItemModal'
import EditFeedItemModal from 'components/modals/EditFeedItemModal'
import DeleteFeedItemModal from 'components/modals/DeleteFeedItemModal'
import NewInvitationModal from 'components/modals/NewInvitationModal'
import AlertModal from 'components/modals/AlertModal'
import FileModal from 'components/modals/FileModal'
import FeedItemModal from 'components/modals/FeedItemModal'

// Setup our context with initial state
const ModalContext = createContext({
  // State
  activeModal: null,
  // Functions
  setModalNone: () => {},
  setModalNewProject: () => {},
  setModalEditProject: () => {},
  setModalDeleteProject: () => {},
  setModalNewFeedItem: () => {},
  setModalEditFeedItem: () => {},
  setModalDeleteFeedItem: () => {},
  setModalNewInvitation: () => {},
  setModalAlert: () => {},
  setModalFile: () => {},
  setModalFeedItem: () => {},
})

// Build our provider (wrapper)
export const ModalProvider = ({ children }) => {
  const [activeModal, setActiveModal] = useState(null)

  const setModalNone = () => setActiveModal(null)

  const setModalNewProject = props =>
    setActiveModal({
      component: NewProjectModal,
    })

  const setModalEditProject = props =>
    setActiveModal({
      component: EditProjectModal,
      props,
    })

  const setModalDeleteProject = props =>
    setActiveModal({
      component: DeleteProjectModal,
      props,
    })

  const setModalNewFeedItem = props =>
    setActiveModal({
      component: NewFeedItemModal,
      props,
    })

  const setModalEditFeedItem = props =>
    setActiveModal({
      component: EditFeedItemModal,
      props,
    })

  const setModalDeleteFeedItem = props =>
    setActiveModal({
      component: DeleteFeedItemModal,
      props,
    })

  const setModalNewInvitation = props =>
    setActiveModal({
      component: NewInvitationModal,
      props,
    })

  const setModalAlert = props =>
    setActiveModal({
      component: AlertModal,
      props,
    })

  const setModalFile = props =>
    setActiveModal({
      component: FileModal,
      props,
    })

  const setModalFeedItem = props =>
    setActiveModal({
      component: FeedItemModal,
      props,
    })

  return (
    <ModalContext.Provider
      value={{
        // State
        activeModal,
        // Functions
        setModalNone,
        setModalNewProject,
        setModalEditProject,
        setModalDeleteProject,
        setModalNewFeedItem,
        setModalEditFeedItem,
        setModalDeleteFeedItem,
        setModalNewInvitation,
        setModalAlert,
        setModalFile,
        setModalFeedItem,
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}

export default ModalContext
