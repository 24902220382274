import React, { useState, useContext } from 'react'
import clsx from 'clsx'
// Utilities
import { withRouter } from 'react-router-dom'
import { isOwner } from 'utils/helpers/permission'
// Contexts
import ProjectContext from 'contexts/ProjectContext'
import ModalContext from 'contexts/ModalContext'
import AuthContext from 'contexts/AuthContext'
// Components
import Header from 'components/elements/Header'
import Dropdown from 'components/elements/Dropdown'
import TitleBar from 'components/elements/TitleBar'
import Button from 'components/elements/Button'
import ExpandableText from 'components/elements/ExpandableText'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ProjectsPage = ({ history }) => {
  const { setModalNone, setModalEditProject, setModalDeleteProject, setModalNewProject, setModalAlert } = useContext(
    ModalContext
  )
  const { current: currentUser } = useContext(AuthContext)
  const { all: allProjects, update: updateProject, leave: leaveProject, isLoaded: isProjectsLoaded } = useContext(
    ProjectContext
  )
  const [openTabs, setOpenTabs] = useState({
    active: true,
    pending: false,
    archived: false,
  })

  const ownedActiveProjectCount = allProjects.filter(proj => proj.role === 'owner' && proj.status === 'active').length
  const projectLimit = currentUser.plan.maxActiveProjects
  const isAtProjectLimit = ownedActiveProjectCount === projectLimit

  const handleToggleTab = tabId => {
    if (tabId === 'active') return

    setOpenTabs(prev => ({
      ...prev,
      [tabId]: !prev[tabId],
    }))
  }

  const handleArchiveProject = ({ project }) => {
    updateProject(project.id, { status: 'archived' })
  }

  const handleActivateProject = ({ project }) => {
    updateProject(project.id, { status: 'active' })
  }

  const handleClickNewProject = () => {
    if (isAtProjectLimit) {
      history.push('billing')
      return
    }
    setModalNewProject()
  }

  const renderArchivedProjects = () => {
    const archivedProjects = allProjects.filter(proj => proj.status === 'archived')
    if (archivedProjects.length === 0) return

    return (
      <>
        <div className="mt-2 first-child:mt-0 transition-border transition-100 transition-height">
          {openTabs.archived && (
            <div className={clsx('transition-250 transition-height')}>{renderProjectRows(archivedProjects)}</div>
          )}
          <div
            className="py-4 px-6 cursor-pointer rounded hover:bg-gray-100"
            onClick={() => handleToggleTab('archived')}
          >
            <p className={clsx('flex-1 font-bold text-center', openTabs.archived ? 'text-black' : 'text-gray-500')}>
              {openTabs.archived ? '- Hide Archived Projects' : ' + Show Archived Projects'}
            </p>
          </div>
        </div>
      </>
    )
  }

  const renderProjects = () => {
    const activeProjects = allProjects.filter(proj => proj.status === 'active')

    return (
      <>
        <div className="mt-2 first-child:mt-0 transition-border transition-100 transition-height border border-gray-400 bg-white shadow rounded">
          <div className="transition-250 transition-height">{renderProjectRows(activeProjects)}</div>
        </div>
      </>
    )
  }

  const renderProjectRows = projects => {
    return projects.map(project => {
      const projectOptions = []
      // Add options based on current user's role
      if (isOwner(project)) {
        // Add edit option
        projectOptions.push({
          id: 'edit',
          text: 'Edit',
          onClick: () => {
            setModalEditProject({ project })
          },
        })
        // Add archive option
        if (['active', 'pending'].some(status => project.status === status)) {
          projectOptions.push({
            id: 'archive',
            text: 'Archive',
            onClick: () => {
              handleArchiveProject({ project })
            },
          })
        }
        // Add archive option
        if (project.status === 'archived') {
          projectOptions.push({
            id: 'activate',
            text: 'Activate',
            onClick: () => {
              handleActivateProject({ project })
            },
          })
        }
        // Add delete option
        projectOptions.push({
          id: 'delete',
          text: 'Delete',
          extraClasses: 'text-red-600',
          onClick: () => {
            setModalDeleteProject({ project })
          },
        })
      } else {
        // Client access only
        projectOptions.push({
          id: 'leave',
          text: 'Leave',
          extraClasses: 'text-red-600',
          onClick: () => {
            setModalAlert({
              onContinue: () => {
                leaveProject(project.id).then(() => {
                  setModalNone()
                })
              },
              submitProps: {
                text: 'Leave',
                color: 'red',
                extraClasses: 'mr-4',
              },
              message: (
                <span>
                  Are you sure you want to leave this project?{' '}
                  <strong className="text-red-500">You will need to be re-invited.</strong>
                </span>
              ),
            })
          },
        })
      }

      const onClickProject = event => {
        history.push(`/projects/${project.id}`)
      }

      return (
        <div key={project.id} className="border-t border-gray-200 first-child:border-t-0">
          <div
            className={clsx('group flex items-center px-6 py-4 cursor-pointer hover:bg-gray-100')}
            onClick={onClickProject}
          >
            {project.icon ? (
              <div className="flex items-center justify-center h-16 w-16 mr-6 bg-white rounded">
                <div className="flex justify-center h-16 w-16 bg-cover bg-no-repeat rounded overflow-hidden">
                  <img className="h-full max-w-none" src={project.icon.sizes.thumb.url} alt={project.icon.meta.name} />
                </div>
              </div>
            ) : null}
            <div className="flex-1">
              <p className="text font-bold text-gray-900 group-hover:text-black">{project.name}</p>
              {project.status !== 'archived' && (
                <p className="text-sm text-gray-800">
                  <ExpandableText text={project.description} />
                </p>
              )}
            </div>
            <div className="hidden md:block" onClick={event => event.stopPropagation()}>
              <Dropdown
                buttonProps={{
                  icon: 'ellipsis-h',
                  color: 'none',
                  extraClasses: 'text-gray-400 hover:text-gray-900',
                }}
                align="right"
                options={projectOptions}
              />
            </div>
          </div>
        </div>
      )
    })
  }

  const renderLoadingView = isProjectsLoaded ? (
    <div className="flex justify-center items-center py-40">
      <FontAwesomeIcon icon="glass-cheers" size="10x" color="#edf2f7" />
    </div>
  ) : (
    <div className="flex justify-center items-center py-40">
      <FontAwesomeIcon icon="spinner" size="2x" color="#ed8936" className="opacity-50" pulse />
    </div>
  )

  return (
    <div>
      <Header />
      <div className="py-24 px-4">
        <div className="max-w-6xl lg:mx-auto">
          <TitleBar title="Active Projects">
            {isProjectsLoaded &&
              (isAtProjectLimit ? (
                <Button onClick={handleClickNewProject} color="gray" text="Upgrade to Create Another Project" />
              ) : (
                <Button
                  onClick={handleClickNewProject}
                  color="orange"
                  text="+ New Project"
                  disabled={isAtProjectLimit}
                />
              ))}
          </TitleBar>

          {!allProjects || allProjects.length === 0 ? (
            renderLoadingView
          ) : (
            <div>
              {renderProjects()}
              {renderArchivedProjects()}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default withRouter(ProjectsPage)
