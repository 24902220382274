import React from 'react'
// Utilities
import { Redirect, Route, Switch } from 'react-router-dom'
// Components
import PublicRoute from 'components/extras/PublicRoute'
// Pages
import LoginPage from 'pages/LoginPage'
import SignupPage from 'pages/SignupPage'

const NonAuthenticatedRoutes = () => {
  return (
    <Switch>
      <PublicRoute path="/join" component={SignupPage} />
      <PublicRoute path="/login" component={LoginPage} />
      <Route path="/" render={({ location }) => <Redirect to={{ pathname: '/login', search: location.search }} />} />
    </Switch>
  )
}

export default NonAuthenticatedRoutes
