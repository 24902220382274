//
// Helper methods to help figure out what the current user can/can't do
//

export const isAdmin = project => {
  return ['admin', 'owner'].indexOf(project.role) !== -1
}

export const isOwner = project => {
  return ['owner'].indexOf(project.role) !== -1
}
