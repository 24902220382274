// Utilities
import baseAxios from './base'

const CommentApi = {
  // Get all of a project's feed items
  list: (projectId, feedItemId, params) => {
    return baseAxios.get(`/projects/${projectId}/feed/${feedItemId}/comments`, { params })
  },

  // Create a comment
  create: (projectId, feedItemId, params) => {
    return baseAxios.post(`/projects/${projectId}/feed/${feedItemId}/comments`, params)
  },

  // Delete a comment
  delete: (projectId, feedItemId, commentId) => {
    return baseAxios.delete(`/projects/${projectId}/feed/${feedItemId}/comments/${commentId}`)
  },
}

export default CommentApi
