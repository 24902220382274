import React, { useContext } from 'react'
// Contexts
import AuthContext from 'contexts/AuthContext'
// Utilities
import { Redirect, Route } from 'react-router-dom'

const PublicRoute = ({ component: Component, ...props }) => {
  const { current: currentUser } = useContext(AuthContext)

  return (
    <Route
      {...props}
      render={innerProps =>
        !currentUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/projects',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

export default PublicRoute
