import React from 'react'
// Utilities
import clsx from 'clsx'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Button = props => {
  const defaultClasses = `flex border rounded font-bold text-sm shadow ${
    props.disabled ? 'opacity-50 cursor-default' : 'focus:outline-none focus:shadow-outline'
  }`

  //
  // Size Classes
  //

  let sizeClasses

  switch (props.size) {
    case 'large':
      sizeClasses = 'px-6 h-12 text-lg'
      break
    default:
      sizeClasses = 'px-4 h-10'
  }

  //
  // Position Classes
  //

  let positionClasses

  switch (props.position) {
    case 'left':
      positionClasses = 'items-center justify-start py-1 text-left'
      break
    default:
      positionClasses = 'items-center justify-center'
  }

  //
  // Color Classes
  //

  let colorClasses = ''

  switch (props.color) {
    case 'none':
      colorClasses = `bg-transparent border-none text-black shadow-none ${
        props.disabled ? 'opacity-50' : 'hover:bg-white-translucent'
      }`
      break
    case 'orange':
      colorClasses = `bg-orange-500 border-orange-700 text-white ${
        props.disabled ? 'opacity-50' : 'hover:bg-orange-400 hover:border-orange-500'
      }`
      break
    case 'red':
      colorClasses = `bg-red-500 border-red-700 text-white ${
        props.disabled ? 'opacity-50' : 'hover:bg-red-400 hover:border-red-500'
      }`
      break
    default:
      colorClasses = `bg-white border-gray-400 ${props.disabled ? 'opacity-50' : 'hover:bg-gray-100'}`
  }

  // Create a single classes element
  const classes = clsx(defaultClasses, sizeClasses, colorClasses, positionClasses, props.extraClasses)
  const clickHandler = !props.disabled ? props.onClick : event => event.preventDefault()

  const button = props.linkTo ? (
    props.linkTo === '#' ? (
      <a className={classes} href={props.linkTo} onClick={clickHandler} disabled={props.disabled}>
        {props.icon && <FontAwesomeIcon icon={props.icon} className={props.text && 'mr-4'} />}
        <span className="flex-1">{props.text}</span>
        {props.prependIcon && <FontAwesomeIcon icon={props.prependIcon} className={props.text && 'mr-4'} />}
      </a>
    ) : (
      <NavLink className={classes} to={props.linkTo} disabled={props.disabled}>
        {props.icon && <FontAwesomeIcon icon={props.icon} className={props.text && 'mr-4'} />}
        <span className="flex-1">{props.text}</span>
        {props.prependIcon && <FontAwesomeIcon icon={props.prependIcon} className={props.text && 'mr-4'} />}
      </NavLink>
    )
  ) : (
    <button className={classes} onClick={clickHandler} disabled={props.disabled} type={props.type}>
      {props.icon && <FontAwesomeIcon icon={props.icon} className={props.text && 'mr-4'} />}
      <span className="flex-1">{props.text}</span>
      {props.prependIcon && <FontAwesomeIcon icon={props.prependIcon} className={props.text && 'ml-4'} />}
    </button>
  )

  return button
}

export default Button
