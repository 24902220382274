import React from 'react'
// Utilities
import { withRouter } from 'react-router-dom'
// Components
import BaseModal from 'components/modals/BaseModal'

const AlertModal = ({ onContinue, submitProps = {}, isSubmitting = false, title, message }) => {
  const onSubmit = event => {
    // Send the ajax request
    onContinue && onContinue()
    event.preventDefault()
  }

  return (
    <BaseModal
      title={title}
      submitProps={submitProps}
      cancelProps={{
        size: 'normal',
        extraClasses: 'mr-4',
      }}
      isSubmitting={isSubmitting}
      onSubmit={onSubmit}
    >
      <p className="py-3 text-gray-800">{message}</p>
    </BaseModal>
  )
}

export default withRouter(AlertModal)
