import React from 'react'
// Contexts
import { ProjectProvider } from 'contexts/ProjectContext'
import { FeedItemProvider } from 'contexts/FeedItemContext'
import { UserProvider } from 'contexts/UserContext'
import { ModalProvider } from 'contexts/ModalContext'
// components
import ModalConductor from 'components/extras/ModalConductor'

const AuthenticatedContainer = ({ children }) => {
  return (
    <ProjectProvider>
      <FeedItemProvider>
        <UserProvider>
          <ModalProvider>
            {children}
            <ModalConductor />
          </ModalProvider>
        </UserProvider>
      </FeedItemProvider>
    </ProjectProvider>
  )
}

export default AuthenticatedContainer
