import React, { useCallback, useContext } from 'react'
// Utilities
import _ from 'lodash'
import UserRoles from 'utils/constants/user-roles'
// Contexts
import UserContext from 'contexts/UserContext'
// Components
import Dropdown from 'components/elements/Dropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ProjectUserList = ({ project, users, updateUser, deleteUser, currentUser }) => {
  // Load in our user functions
  const { isLoaded: isUsersLoaded } = useContext(UserContext)

  const onChangeRole = useCallback(
    (newVal, payload) => {
      const user = payload.user
      if (!user) {
        return
      }
      // Don't update if we have the same role!
      if (user.role === newVal) {
        return
      }
      // Update our user!
      updateUser(user.id, { role: newVal })
    },
    [updateUser]
  )

  const onRemoveUser = useCallback(
    (event, payload) => {
      const user = payload.user
      if (!user) {
        return
      }
      // Update our user!
      deleteUser(user.id)
    },
    [deleteUser]
  )

  // Event Handlers

  const renderUserList = () => {
    const allUsers = [
      ...(users.joined || []).map(user => Object.assign({}, user, { type: 'joined' })),
      ...(users.invited || []).map(user => Object.assign({}, user, { type: 'invited' })),
    ]

    return !_.isEmpty(allUsers)
      ? allUsers.map(user => {
          const projectDropdownOptions = {
            align: 'right',
            options: [
              {
                id: 'remove',
                text: 'Remove',
                onClick: onRemoveUser,
              },
            ],
            buttonProps: {
              text: 'Options',
              extraClasses: 'ml-2',
            },
            payload: { project, user },
          }

          const roleDropdownOptions = {
            align: 'right',
            options: Object.values(UserRoles),
            omitOptions: [UserRoles['owner']],
            onChange: onChangeRole,
            payload: { user },
            selected: user.role,
            selectable: true,
            disabled: user.id === currentUser.id || user.role === 'owner',
          }

          const renderJoined = user => {
            // Render a disclaimer as to why you can't do something
            const renderDisclaimer = () => {
              let message
              if (user.id === currentUser.id) {
                message = <span className="mr-6 py-2 text-gray-500 text-sm italic">Cannot modify your own role</span>
              } else if (user.role === 'owner') {
                message = <span className="mr-6 py-2 text-gray-500 text-sm italic">Cannot change project owner</span>
              }
              return message
            }

            return (
              <div key={`joined-${user.id}`} className="border-t border-gray-200 first-child:border-t-0">
                <div className="group flex flex-col items-start px-6 py-4 md:items-center md:flex-row">
                  <div className="flex-1">
                    <p className="font-bold text-black">{user.email}</p>
                  </div>
                  {user.type === 'joined' ? (
                    <div
                      className="flex flex-row justify-end mt-4 md:items-center md:mt-0"
                      onClick={event => event.stopPropagation()}
                    >
                      {renderDisclaimer()}
                      <Dropdown {...roleDropdownOptions} />
                      {user.id !== currentUser.id && user.role !== 'owner' && <Dropdown {...projectDropdownOptions} />}
                    </div>
                  ) : (
                    <div>Invited</div>
                  )}
                </div>
              </div>
            )
          }

          const renderInvited = user => (
            <div key={`invited-${user.id}`} className="border-t border-gray-200 first-child:border-t-0">
              <div className="group flex items-center p-6">
                <div className="flex-1">
                  <p className="text-lg text-gray-600 italic">{user.email}</p>
                </div>
                <div className="text-gray-600 italic">Invited</div>
              </div>
            </div>
          )

          return user.type === 'joined' ? renderJoined(user) : renderInvited(user)
        })
      : null
  }

  return !isUsersLoaded ? (
    <div className="p-8 lg:px-0 h-auto mt-8 mx-auto text-center">
      <FontAwesomeIcon icon="spinner" size="2x" color="#ed8936" className="opacity-50" pulse />
    </div>
  ) : (
    <div className="min-h-32 lg:mx-auto border border-gray-400 rounded bg-white shadow">{renderUserList()}</div>
  )
}

export default ProjectUserList
