import React, { createContext, useCallback, useEffect, useState } from 'react'
// Utilities
import { withRouter } from 'react-router'
import _ from 'lodash'
import ProjectApi from 'utils/api/project'

// Setup our context with initial state
const ProjectContext = createContext({
  // Data
  all: [],
  current: null,
  // State
  isLoaded: false,
  // AJAX Functions
  load: () => {},
  create: () => {},
  update: () => {},
  destroy: () => {},
  reset: () => {},
  leave: () => {},
})

// Build our provider (wrapper)
export const ProjectProvider = withRouter(({ location, children }) => {
  const [all, setAll] = useState([])
  const [current, setCurrent] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)

  // Watch for changes to our route and set our current project
  useEffect(() => {
    const locationParts = location.pathname.split('/')
    // NOTE: Parts will look something like ["", "projects", "123"]
    // Make sure we're on a projects page at all
    if (locationParts[1] !== 'projects') {
      return
    }
    // If we are, update our current project to this id OR null
    const projectId = locationParts[2] ? parseInt(locationParts[2]) : null
    // If our project is the same, we're good here
    if (current && _.isEqual(current.id, projectId)) {
      return
    }
    // Something has changed, set our new project!
    const project = _.find(all, { id: projectId })
    setCurrent(project)
  }, [all, current, location.pathname])

  // Load all items
  const load = useCallback(params => {
    return ProjectApi.list(params).then(response => {
      setAll(response.data)
      setIsLoaded(true)
      return response
    })
  }, [])

  // Create a project
  const create = useCallback(params => {
    return ProjectApi.create(params).then(response => {
      setAll(prevAll => [response.data, ...prevAll])
      return response
    })
  }, [])

  // Update a project
  const update = useCallback((projectId, params) => {
    return ProjectApi.update(projectId, params).then(response => {
      setAll(prevAll => {
        const all = prevAll.slice()
        // Replace a project at a specific index
        const index = _.findIndex(all, { id: response.data.id })
        all.splice(index, 1, response.data)
        return all
      })
      return response
    })
  }, [])

  // Delete a project
  const destroy = useCallback((projectId, name) => {
    return ProjectApi.delete(projectId, name).then(response => {
      setAll(prevAll => _.filter(prevAll, proj => proj.id !== projectId))
      return response
    })
  }, [])

  // Leave a project
  const leave = useCallback(projectId => {
    return ProjectApi.leave(projectId).then(response => {
      setAll(prevAll => _.filter(prevAll, proj => proj.id !== projectId))
      return response
    })
  }, [])

  // Reset all of our data and reload
  const reset = useCallback(() => {
    // Reset things
    setIsLoaded(false)
    setAll([])
    setCurrent(null)
  }, [])

  return (
    <ProjectContext.Provider
      value={{
        // Data
        all,
        current,
        // State
        isLoaded,
        // AJAX Functions
        load,
        create,
        update,
        destroy,
        reset,
        leave,
      }}
    >
      {children}
    </ProjectContext.Provider>
  )
})

export const ProjectConsumer = ProjectContext.Consumer
export default ProjectContext
