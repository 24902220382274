import React, { createContext, useCallback, useContext, useState } from 'react'
// Contexts
import ProjectContext from 'contexts/ProjectContext'
// Utilities
import _ from 'lodash'
import FeedItemApi from 'utils/api/feed_items'

// Setup our context with initial state
const FeedItemContext = createContext({
  // Data
  all: [],
  // State
  projectId: null,
  isLoaded: false,
  isAtEnd: false,
  totalCount: null,
  // Functions
  load: () => {},
  create: () => {},
  update: () => {},
  destroy: () => {},
  reset: () => {},
  findById: () => {},
  updateLocal: () => {},
})

// Build our provider (wrapper)
export const FeedItemProvider = ({ children }) => {
  const { current: project } = useContext(ProjectContext)
  const [all, setAll] = useState([])
  const [totalCount, setTotalCount] = useState(null)
  const [projectId, setProjectId] = useState(null)
  const [isAtEnd, setIsAtEnd] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)

  const PAGINATION_PAGE_SIZE = 15

  // Load new feed items from our API
  const load = useCallback(
    (params = {}) => {
      // Can't do this without a project!
      if (!project) {
        return
      }

      // Setup our pagination
      params.page = {
        ...params.page,
        size: PAGINATION_PAGE_SIZE,
      }

      return FeedItemApi.list(project.id, params).then(response => {
        // Update our items
        setAll(prevAll => [...prevAll, ...response.data.data])
        // Set our new meta data
        setTotalCount(response.data.meta.total)
        // Tell the UI we've loaded
        setIsLoaded(true)
        // Store this project ID so we know if we need to reset it
        setProjectId(project.id)
        // If we get back less items than we want, we've reached the end
        setIsAtEnd(_.size(response.data.data) < PAGINATION_PAGE_SIZE)
        // Return our response for chaining
        return response
      })
    },
    [project]
  )

  // Create a new feed item
  const create = useCallback(
    (params = {}) => {
      // Can't do this without a project!
      if (!project) {
        return
      }
      return FeedItemApi.create(project.id, params).then(response => {
        setAll(prevAll => [response.data.data, ...prevAll])
        // Set our new meta data
        setTotalCount(response.data.meta.total)
      })
    },
    [project]
  )

  // Update a feed item
  const update = useCallback(
    (id, params = {}) => {
      // Can't do this without a project!
      if (!project) {
        return
      }
      return FeedItemApi.update(project.id, id, params).then(response => {
        setAll(prevAll => {
          const newAll = _.cloneDeep(prevAll)
          // Replace the existing item by id
          const index = _.findIndex(newAll, { id: id })
          newAll.splice(index, 1, response.data.data)
          return newAll
        })
        // Set our new meta data
        setTotalCount(response.data.meta.total)
      })
    },
    [project]
  )

  // Delete a feed item
  const destroy = useCallback(
    id => {
      // Can't do this without a project!
      if (!project) {
        return
      }
      return FeedItemApi.delete(project.id, id).then(response => {
        setAll(prevAll => prevAll.filter(item => item.id !== id))
        // Set our new meta data
        setTotalCount(response.data.meta.total)
      })
    },
    [project]
  )

  // Either find the feed item locally OR go fetch a new one
  const findById = useCallback(
    id => {
      // See if we have one already
      let item = _.find(all, { id: parseInt(id) })
      if (item) {
        return Promise.resolve({ data: item })
      }
      // We don't have one, go get it
      return FeedItemApi.find(project.id, id)
    },
    [project, all]
  )

  // Replace a local copy with an updated version (if it exists)
  const updateLocal = useCallback(
    item => {
      // See if we have one stored to update
      let index = _.findIndex(all, { id: parseInt(item.id) })
      if (index === -1) {
        return
      }
      // Update our items array
      const newAll = _.cloneDeep(all)
      newAll[index] = item
      // Store our new items
      setAll(newAll)
    },
    [all]
  )

  // Reset all of our data and reload
  const reset = useCallback(() => {
    // Reset our current feed items
    setProjectId(null)
    setIsLoaded(false)
    setIsAtEnd(false)
    setTotalCount(null)
    setAll([])
  }, [])

  return (
    <FeedItemContext.Provider
      value={{
        // Data
        all,
        // State
        isLoaded,
        isAtEnd,
        totalCount,
        projectId,
        // Functions
        reset,
        load,
        create,
        update,
        destroy,
        findById,
        updateLocal,
      }}
    >
      {children}
    </FeedItemContext.Provider>
  )
}

export default FeedItemContext
