import React, { useEffect, useState } from 'react'
// Utilities
import clsx from 'clsx'
import { useDropzone } from 'react-dropzone'
// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const AvatarUpload = ({ image, size, onChange, disabled, extraClasses, extraStyles, placeholderIcon, hidePlus }) => {
  const [file, setFile] = useState(null)

  // Setup our dropzone
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop: acceptedFiles => {
      const file = acceptedFiles[0]
      // If we require one item, just replace it
      setFile(
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
      // Just return our active file(s)
      onChange && onChange(file)
    },
  })

  // Make sure to revoke the data uris to avoid memory leaks
  useEffect(() => {
    return () => {
      file && URL.revokeObjectURL(file.preview)
    }
  }, [file])

  const previewStyles =
    file && file.preview
      ? {
          backgroundImage: `url(${file.preview})`,
        }
      : null

  // Render Helpers

  const renderFile = () => {
    const fileToRender = file
      ? {
          id: file.id,
          name: file.name || file.meta.name,
        }
      : null

    // Remove our file
    const onRemoveFile = event => {
      setFile(null)
      // Just return our active file(s)
      onChange && onChange(null)
      event.preventDefault()
      event.stopPropagation()
      return false
    }

    return fileToRender ? (
      <div className="relative h-30 w-30 bg-gray-100 bg-cover bg-no-repeat rounded-full" style={previewStyles}>
        <button
          className="absolute flex items-center justify-center bg-overlay-black top-0 right-0 w-6 h-6 rounded-full text-white"
          style={{ marginTop: '-0.75rem', marginRight: '-0.75rem' }}
          onClick={onRemoveFile}
        >
          <FontAwesomeIcon icon="times" size="xs" />
        </button>
      </div>
    ) : (
      <div className="relative flex justify-center items-center h-30 w-30 bg-gray-100 bg-cover bg-no-repeat rounded-full">
        {placeholderIcon && <FontAwesomeIcon icon={placeholderIcon} size="3x" className="text-gray-400" />}
        {!hidePlus && (
          <button
            className="absolute flex items-center justify-center bg-gray-300 top-0 right-0 w-6 h-6 rounded-full text-white"
            style={{ marginTop: '-0.75rem', marginRight: '-0.75rem' }}
            onClick={event => event.preventDefault()}
          >
            <FontAwesomeIcon icon="plus" size="xs" />
          </button>
        )}
      </div>
    )
  }

  return (
    <div
      {...getRootProps()}
      className={clsx(
        'flex items-center justify-center h-32 w-32 mb-4 bg-white border border-gray-400 rounded-full',
        !file && 'border-dashed',
        extraClasses
      )}
      style={extraStyles}
    >
      <input {...getInputProps()} />
      {renderFile()}
    </div>
  )
}

export default AvatarUpload
