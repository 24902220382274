import React, { useContext, useEffect, useState, useCallback } from 'react'
// Contexts
import AuthContext from 'contexts/AuthContext'
// Components
import Header from 'components/elements/Header'
import AccountNav from 'components/AccountNav'
import { CardElement, Elements, StripeProvider, injectStripe } from 'react-stripe-elements'
import Dropdown from 'components/elements/Dropdown'
import Button from 'components/elements/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const BillingSection = injectStripe(({ stripe }) => {
  const { current: currentUser, billingInfo, subscribe: subscribeUser, addCard } = useContext(AuthContext)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSubmittingCard, setIsSubmittingCard] = useState(false)

  const [defaultCardId, setDefaultCardId] = useState(null)
  const [selectedPlanId, setSelectedPlanId] = useState(null)
  const [selectedCardId, setSelectedCardId] = useState(null)

  const [isAddingNewCard, setIsAddingNewCard] = useState(false)
  const [isAddCardComplete, setIsAddCardComplete] = useState(false)

  const plans = ((billingInfo && billingInfo.plans) || []).map(plan => {
    const price = (plan.priceCents / 100).toFixed(2)
    const tax = (plan.taxCents / 100).toFixed(2)
    const total = (parseFloat(price) + parseFloat(tax)).toFixed(2)

    return {
      ...plan,
      price,
      tax,
      total,
    }
  })

  const isSubmitEnabled =
    !isSubmitting &&
    selectedCardId &&
    selectedPlanId &&
    (!currentUser.plan ||
      currentUser.plan.id !== selectedPlanId ||
      (selectedCardId && selectedCardId !== defaultCardId))

  useEffect(() => {
    if (!billingInfo) return

    if (!selectedPlanId) {
      setSelectedPlanId(currentUser.plan ? currentUser.plan.id : plans[0].id)
    }

    if (billingInfo.cards.length === 0) {
      setIsAddingNewCard(true)
      return
    }

    if (billingInfo.subscription) {
      setDefaultCardId(billingInfo.subscription.default_source)
      setSelectedCardId(billingInfo.subscription.default_source || billingInfo.cards[0].id)
      return
    }

    setSelectedCardId(billingInfo.cards[0].id)
  }, [billingInfo, currentUser.plan, plans, selectedPlanId, selectedCardId])

  const handleCardSubmit = async event => {
    event.persist()
    event.preventDefault()
    event.stopPropagation()

    if (!isAddCardComplete || isSubmittingCard) return
    setIsSubmittingCard(true)

    let { token } = await stripe.createToken({ name: 'Name' })
    try {
      const card = await addCard({ token: token.id })
      setSelectedCardId(card.id)
      setIsAddingNewCard(false)
      setIsSubmittingCard(false)
    } catch (err) {
      setIsSubmittingCard(false)
    }
  }

  const handleSubmit = async event => {
    event.persist()
    event.preventDefault()
    event.stopPropagation()

    if (!selectedPlanId || !selectedCardId) return
    if (isSubmittingCard || isSubmitting) return
    setIsSubmitting(true)

    const selectedPlan = plans.filter(plan => plan.id === selectedPlanId)[0]

    try {
      await subscribeUser({
        card_id: selectedCardId,
        plan_id: selectedPlan.planIdentifier,
      })
      setIsSubmitting(false)
    } catch (err) {
      setIsSubmitting(false)
    }
  }

  const handleCardChange = useCallback(cardId => {
    cardId && setSelectedCardId(cardId)
  }, [])

  const handleAddNewCard = event => {
    setIsAddingNewCard(true)
    event.preventDefault()
    event.stopPropagation()
  }

  const handleChooseExistingCard = event => {
    setIsAddingNewCard(false)
    event.preventDefault()
    event.stopPropagation()
  }

  const handleCardElementChange = changeEvent => {
    setIsAddCardComplete(changeEvent.complete)
  }

  const renderPlans = () => {
    return (
      <div className="mb-6 border border-1 border-gray-300 rounded">
        {plans.map(plan => {
          const isActive = plan.id === selectedPlanId

          return isActive ? (
            <div
              key={plan.id}
              className="flex items-center px-6 py-4 border-t border-gray-300 first-child:border-t-0 last-child:rounded-b first-child:rounded-t shadow"
            >
              <div className="flex items-center justify-center">
                <div className="flex items-center justify-center w-4 h-4 rounded-full bg-orange-500 border-orange-700">
                  <div className="w-2 h-2 bg-orange-100 rounded-full"></div>
                </div>
              </div>
              <div className="flex-1 ml-6">
                <p className={'text font-bold text-gray-900'}>{plan.name}</p>
                <p className="text-sm text-gray-800">
                  <span className="font-bold">Active Projects:</span> {plan.activeProjects}
                </p>
              </div>
            </div>
          ) : (
            <div
              key={plan.id}
              className="group flex items-center px-6 py-4 border-t border-gray-300 bg-gray-100 cursor-pointer hover:bg-white first-child:border-t-0 last-child:rounded-b first-child:rounded-t"
              onClick={() => setSelectedPlanId(plan.id)}
            >
              <div className="flex items-center justify-center">
                <div className="flex items-center justify-center w-4 h-4 rounded-full border border-gray-400">
                  <div className="w-2 h-2 rounded-full group-hover:bg-gray-300"></div>
                </div>
              </div>
              <div className="flex-1 ml-6">
                <p className={'text font-bold text-gray-500 group-hover:text-gray-600'}>{plan.name}</p>
                <p className="text-sm text-gray-500 group-hover:text-gray-500">
                  <span className="font-bold">Active Projects:</span> {plan.maxActiveProjects}
                </p>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  const renderPlanSummary = () => {
    const selectedPlan = plans.filter(plan => plan.id === selectedPlanId)[0]

    return selectedPlanId ? (
      <div className="px-2 border border-gray-200 bg-white rounded text-sm">
        <div>
          <p className="p-2 font-bold border-b border-gray-200">Plan Summary</p>
        </div>
        <div className="p-2">
          <div className="flex text-black font-bold text-base">
            <p className="flex-1">{selectedPlan.name}</p>
            <p>${selectedPlan.price}</p>
          </div>
        </div>
        <div className="p-2">
          <div className="flex">
            <p className="flex-1">Tax</p>
            <p>${selectedPlan.tax}</p>
          </div>
        </div>
        <div className="p-2 border-t border-gray-200">
          <div className="flex font-bold">
            <p className="flex-1">Total Monthly</p>
            <p>${selectedPlan.total}</p>
          </div>
        </div>
      </div>
    ) : null
  }

  const renderPaymentMethod = () => {
    const cards = (billingInfo.cards || []).map(card => ({
      id: card.id,
      text: `${card.brand} ****${card.last4} (exp. ${card.expMonth}/${card.expYear})`,
    }))

    return (
      <>
        {isAddingNewCard && (
          <>
            <div className="flex">
              <div className="flex-1 p-3 h-10 mr-2 border border-gray-400 rounded bg-gray-100">
                <CardElement onChange={handleCardElementChange} />
              </div>
              <Button text="Add Card" onClick={handleCardSubmit} disabled={!isAddCardComplete || isSubmittingCard} />
              {cards.length > 0 && (
                <Button
                  extraClasses="ml-2"
                  text="Cancel"
                  onClick={handleChooseExistingCard}
                  color="none"
                  className="text-sm text-orange-500 font-bold hover:text-orange-400"
                />
              )}
            </div>
          </>
        )}
        {!isAddingNewCard && (
          <div className="flex">
            {cards.length > 0 && (
              <div className="flex-1">
                <Dropdown
                  align="left"
                  options={cards}
                  selected={selectedCardId}
                  buttonProps={{
                    color: 'gray',
                    prependIcon: 'caret-down',
                    position: 'left',
                    extraClasses: 'w-full',
                  }}
                  selectable={true}
                  onChange={handleCardChange}
                />
              </div>
            )}
            <Button
              extraClasses="ml-2"
              text="+ Add New Payment Method"
              onClick={handleAddNewCard}
              color="none"
              className="text-sm text-orange-500 font-bold hover:text-orange-400"
            />
          </div>
        )}
      </>
    )
  }

  return !billingInfo ? (
    <div className="flex justify-center items-center py-40">
      <FontAwesomeIcon icon="spinner" size="2x" color="#ed8936" className="opacity-50" pulse />
    </div>
  ) : (
    <div>
      <div className="">
        <div className="">
          <h2 className="pb-6 mb-6 border-b border-gray-200 text-lg font-bold">Your Plan</h2>
          {renderPlans()}
          {renderPlanSummary()}
        </div>
        <div>
          <h2 className="py-6 my-6 border-b border-gray-200 text-lg font-bold">Payment Method</h2>
          <div className="mt-6 w-full lg:w-2/3">{renderPaymentMethod()}</div>
        </div>
      </div>
      <div className="pt-6 mt-12 border-t border-gray-200">
        <Button
          text={isSubmitting ? 'Saving...' : 'Save'}
          size="large"
          color="orange"
          extraClasses="w-full md:w-auto"
          onClick={handleSubmit}
          disabled={!isSubmitEnabled}
        />
      </div>
    </div>
  )
})

const StripeWrappedBillingSection = props => {
  return (
    <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUB_KEY}>
      <Elements>
        <BillingSection {...props} />
      </Elements>
    </StripeProvider>
  )
}

const BillingPage = () => {
  const { billing: loadBillingInfo } = useContext(AuthContext)

  // Event Handlers

  useEffect(() => {
    loadBillingInfo()
  }, [loadBillingInfo])

  return (
    <div>
      <Header />
      <div className="py-16 max-w-7xl lg:mx-auto">
        <div className="flex mt-12 px-6 items-start mb-6 flex-col md:flex-row lg:mx-auto">
          <AccountNav />
          <div className="flex-1 bg-white mt-6 w-full md:w-auto md:mt-0 md:ml-6 border border-gray-400 rounded shadow">
            <div className="p-6 border-b border-gray-200">
              <h1 className="text-xl font-bold">Billing & Plans</h1>
            </div>
            <div className="p-6">
              <StripeWrappedBillingSection />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BillingPage
