// Utilities
import baseAxios from './base'

const AuthApi = {
  // Log in a user
  loginUser: params => {
    return baseAxios.post('/users/sign_in', params)
  },

  // Sign up a user
  signupUser: params => {
    return baseAxios.post('/users/sign_up', formDataFromParams(params))
  },

  // Update the current user
  updateUser: params => {
    return baseAxios.put('/user', formDataFromParams(params))
  },

  // Get a user's billing info
  billing: params => {
    return baseAxios.get('/user/billing', params)
  },

  // Subscribe a user
  subscribe: params => {
    return baseAxios.post('/user/subscribe', params)
  },

  // Subscribe a user
  addCard: params => {
    return baseAxios.post('/user/add_card', params)
  },

  // Load user
  loadUser: params => {
    return baseAxios.get('/user', params)
  },
}

const formDataFromParams = params => {
  let formData = new FormData()
  // Add the fields we care about
  formData.append('user[name]', params.name)
  formData.append('user[email]', params.email)
  formData.append('user[signed_up_as]', params['signed_up_as'])
  formData.append('user[password]', params.password)
  // TODO:: Ugh this is fuckin ugly. Find a better way to do uploads and purging...
  // If we've aleady saved it, just send up the ID so we don't purge it
  if (params.removeIcon) {
    formData.append('user[remove_icon]', true)
  }
  if (params.icon && !params.icon.id) {
    formData.append('user[icon]', params.icon)
  }

  return formData
}

export default AuthApi
