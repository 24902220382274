import React, { useContext, useEffect, useState } from 'react'
// Utilities
import _ from 'lodash'
import { withRouter } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
// Contexts
import ModalContext from 'contexts/ModalContext'
import FeedItemContext from 'contexts/FeedItemContext'
// Components
import BaseModal from 'components/modals/BaseModal'
import FileList from 'components/elements/FileList'
import Button from 'components/elements/Button'

const EditFeedItemModal = ({ project, item }) => {
  const { setModalNone } = useContext(ModalContext)
  const { update: updateFeedItem } = useContext(FeedItemContext)
  // Store our status fields
  const [title, setTitle] = useState(item.title)
  const [description, setDescription] = useState(item.description)
  const [files, setFiles] = useState([])
  const [removedFiles, setRemovedFiles] = useState([])
  const [canAttachFiles, setCanAttachFiles] = useState(!_.isEmpty(item.files))
  // Store some things that will update the UI
  const [formErrors, setFormErrors] = useState({})
  const [submittingForm, setSubmittingForm] = useState(false)
  // Setup our dropzone
  const { getRootProps, getInputProps } = useDropzone({
    accept: '.xlsx, .xls, image/*, .doc, .docx, .ppt, .pptx, .txt, .pdf',
    onDrop: acceptedFiles => {
      // Add any newly selected files to our file list
      const newFiles = _.uniqBy([...files, ...acceptedFiles], file => file.name)
      setFiles(newFiles)
    },
  })

  // Make sure to revoke the data uris to avoid memory leaks
  useEffect(() => {
    files.forEach(file => URL.revokeObjectURL(file.preview))
  }, [files])

  //
  // Form Validations
  //

  const validateForm = () => {
    return new Promise((resolve, reject) => {
      const errors = []

      // Make sure we actually validate :)
      if (_.isEmpty(title)) {
        // Invalid
        errors.push({
          parameter: 'title',
          message: 'Please enter a valid title',
        })
      }

      // Resolve or reject based on errors
      _.isEmpty(errors) ? resolve() : reject(errors)
    })
  }

  const renderError = (type, classes = '') => {
    // Grab the error from our errors object
    const error = formErrors[type]
    const classNames = `py-2 px-4 mt-2 bg-red-400 text-white text-left text-sm rounded ${classes}`

    return error ? <div className={classNames}>{error.message}</div> : null
  }

  //
  // Event Handlers
  //

  const onSubmit = event => {
    event.preventDefault()
    // Validate our form
    validateForm()
      .then(() => {
        // We're good to submit!
        setFormErrors({})
        setSubmittingForm(true)
        // Send the ajax request
        updateFeedItem(item.id, {
          title: title,
          description: description,
          files: files,
          removedFiles: removedFiles,
        })
          .then(() => {
            setModalNone()
          })
          .catch(error => {
            // Store the form errors we got back
            setFormErrors(_.keyBy(error.response.data, 'parameter'))
            setSubmittingForm(false)
          })
      })
      .catch(errors => {
        // We are not ready to submit yet
        setFormErrors(_.keyBy(errors, 'parameter'))
        setSubmittingForm(false)
      })
  }

  const onRemoveFile = file => {
    if (file.id) {
      // If this file has an id, that means we've already stored and returned it, so just add it to our list of removedFiles
      setRemovedFiles([...removedFiles, file.id])
    } else {
      // This is/was a new file we were uploading so just remove it so we don't send it up
      setFiles(files.filter(existingFile => existingFile.name !== file.name))
    }
  }

  const onClickAddAttachments = event => {
    event.preventDefault()
    setCanAttachFiles(true)
  }

  // Render Helpers

  const renderFiles = () => {
    // Combine our files that were on the item already with our newly updated ones
    const existingFiles = (item.files || [])
      .map(file => ({
        id: file.id,
        name: file.meta.name,
      }))
      .filter(file => removedFiles.indexOf(file.id) === -1)
    const activeFiles = [...existingFiles, ...files]

    return <FileList files={activeFiles} onRemove={onRemoveFile} />
  }

  return (
    <BaseModal
      title="Post a Status Update"
      submitProps={{
        text: submittingForm ? 'Updating...' : 'Update',
        size: 'large',
        color: 'orange',
        extraClasses: 'mr-4',
      }}
      isSubmitting={submittingForm}
      onSubmit={onSubmit}
    >
      <label className="block mb-2 text-sm font-bold">Title*:</label>
      <input
        className="w-full p-4 border shadow-inner rounded bg-gray-100 focus:bg-white outline-none focus:shadow-outline"
        onChange={event => setTitle(event.target.value)}
        value={title}
        placeholder="Enter a title for your update"
        disabled={submittingForm}
      />
      {renderError('title')}
      <label className="block mb-2 mt-4 text-sm font-bold">Description:</label>
      <textarea
        className="w-full p-4 h-24 border shadow-inner rounded bg-gray-100 focus:bg-white outline-none focus:shadow-outline"
        onChange={event => setDescription(event.target.value)}
        value={description}
        placeholder="What happened?"
        disabled={submittingForm}
      />
      {canAttachFiles && (
        <>
          <div
            {...getRootProps()}
            className="flex items-center justify-center mt-4 h-24 w-full border-2 border-dashed text-gray-600 rounded cursor-pointer hover:border-orange-400 hover:bg-orange-100 hover:text-orange-600"
          >
            <input {...getInputProps()} />
            <p className="text-sm">Drag 'n' drop some files here, or click to select files</p>
          </div>
          {renderFiles()}
        </>
      )}
      {!canAttachFiles && <Button text="+ Add Attachments" color="none" onClick={onClickAddAttachments} />}
    </BaseModal>
  )
}

export default withRouter(EditFeedItemModal)
