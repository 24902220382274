import React, { useContext, useRef, useState } from 'react'
// Utilities
import _ from 'lodash'
// Contexts
import AuthContext from 'contexts/AuthContext'
// Components
import Header from 'components/elements/Header'
import Button from 'components/elements/Button'
import FieldUpload from 'components/elements/FieldUpload'
import AccountNav from 'components/AccountNav'

const AccountPage = () => {
  const { current: currentUser, update: updateUser } = useContext(AuthContext)
  const [icon, setIcon] = useState(null)
  const nameRef = useRef()
  const emailRef = useRef()
  const [formErrors, setFormErrors] = useState({})
  const [isUpdating, setIsUpdating] = useState(false)

  // Event Handlers

  const onIconChange = file => {
    setIcon(file)
  }

  const onUpdate = event => {
    setIsUpdating(true)
    setFormErrors({})
    updateUser({
      icon: icon,
      name: nameRef.current.value,
      email: emailRef.current.value,
    })
      .then(() => {
        // TODO: Do something
        setIsUpdating(false)
      })
      .catch(error => {
        // Process & store the errors
        setFormErrors(_.keyBy(error.response.data, err => _.camelCase(err.parameter)))
        setIsUpdating(false)
      })

    event.preventDefault()
    event.stopPropagation()
    return false
  }

  // Render Helpers

  const renderError = (type, classes = '') => {
    // Grab the error from our errors object
    const error = formErrors[type]
    const classNames = `py-2 px-4 mt-2 bg-red-400 text-white text-left text-sm rounded ${classes}`

    return error ? <div className={classNames}>{error.message}</div> : null
  }

  return (
    <div>
      <Header />
      <div className="py-16 max-w-7xl lg:mx-auto">
        <div className="flex mt-12 px-6 items-start mb-6 lg:mx-auto flex-col md:flex-row">
          <AccountNav />

          <div className="flex-1 bg-white mt-6 w-full md:w-auto md:mt-0 md:ml-6 border border-gray-400 rounded shadow">
            <div className="p-6 border-b border-gray-200">
              <h1 className="text-xl font-bold">Account Settings</h1>
            </div>
            <div className="p-6">
              {renderError('session', 'mb-4')}
              <form onSubmit={event => onUpdate(event)}>
                <label className="block py-2 text-sm text-gray-600 font-bold">Avatar*</label>
                <FieldUpload onChange={onIconChange} initialFile={currentUser.icon} />
                <label className="block mt-4 py-2 text-sm text-gray-600 font-bold">Name*</label>
                <input
                  ref={nameRef}
                  className="w-full p-4 border border-gray-400 shadow-inner rounded bg-gray-100 max-w-xl focus:bg-white outline-none focus:shadow-outline-orange"
                  placeholder="Name"
                  defaultValue={currentUser.name}
                />
                {renderError('name')}
                <label className="block mt-4 py-2 text-sm text-gray-600 font-bold">Email*</label>
                <input
                  ref={emailRef}
                  className="w-full p-4 border border-gray-400 shadow-inner rounded bg-gray-100 max-w-xl focus:bg-white outline-none focus:shadow-outline-orange"
                  placeholder="Email"
                  defaultValue={currentUser.email}
                />
                {renderError('email')}
                <div className="pt-6 mt-12 border-t border-gray-200">
                  <Button
                    text={isUpdating ? 'Saving...' : 'Save'}
                    size="large"
                    color="orange"
                    extraClasses="w-full md:w-auto"
                    disabled={isUpdating}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountPage
