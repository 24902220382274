import React from 'react'

const TitleBar = ({ title, children }) => {
  return (
    <div className="pb-6 mb-6 lg:mx-auto flex items-center border-b border-gray-200 text-gray-900">
      <div className="flex-1 px-4">
        <h1 className="text-xl font-bold pt-1">{title}</h1>
      </div>
      <div className="flex items-center">{children}</div>
    </div>
  )
}

export default TitleBar
