// Utilities
import axios from 'axios'
import Qs from 'qs'
import JWTDecode from 'jwt-decode'

const BaseAxios = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  responseType: 'json',
  headers: {
    'Api-Version': process.env.REACT_APP_API_VERSION,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  paramsSerializer: function(params) {
    return Qs.stringify(params, { arrayFormat: 'brackets' })
  },
})

//
// Add an interceptor to add the request header
// fron localStorage before each request
//

BaseAxios.interceptors.request.use(
  config => {
    // Make sure we're only hitting our own URL and that we haven't set an auth header manually
    if (config.baseURL === process.env.REACT_APP_API_HOST && !config.headers.Authorization) {
      const token = localStorage.getItem('cltkn')
      // Determine if the token has expired
      if (token) {
        const decoded = JWTDecode(token)
        const expired = Date.now() > decoded.exp * 1000
        //only add our token if it's not expired
        if (!expired) {
          config.headers.Authorization = `Bearer ${token}`
        } else {
          //TODO: Support refreshing tokens...
        }
      }
    }

    return config
  },
  error => Promise.reject(error)
)

export default BaseAxios
