import React, { useContext, useEffect } from 'react'
// Utilities
import _ from 'lodash'
import { withRouter } from 'react-router-dom'
// Contexts
import ProjectContext from 'contexts/ProjectContext'
import FeedItemContext from 'contexts/FeedItemContext'
import ModalContext from 'contexts/ModalContext'
// Components
import ProjectHeader from 'components/ProjectHeader'
import ProjectFeed from 'components/ProjectFeed'
import Header from 'components/elements/Header'
import Button from 'components/elements/Button'
import { isOwner, isAdmin } from 'utils/helpers/permission'

const ProjectFeedPage = ({ match }) => {
  const { setModalNone, setModalFeedItem, setModalNewFeedItem, activeModal } = useContext(ModalContext)
  const { isLoaded: isProjectsLoaded, all: allProjects, current: project } = useContext(ProjectContext)
  const isActiveProject = project && (project.status === 'active' || isOwner(project))

  // Watch to see on load if we are linking to a feed item to display the modal
  useEffect(() => {
    if (!project || activeModal || !isActiveProject) {
      return
    }

    if (match.params.feedItemId) {
      setModalFeedItem({ project: project, itemId: match.params.feedItemId })
    } else {
      setModalNone()
    }
  }, [project, match.params.feedItemId, setModalFeedItem, activeModal, setModalNone, isActiveProject])

  // Load in our feed item functions
  const {
    // State
    all: feedItems,
    isLoaded,
    isAtEnd,
    // Functions
    reset: resetFeedItems,
    load: loadFeedItems,
  } = useContext(FeedItemContext)

  // Watch for our project to change and load in more
  useEffect(() => {
    // Make sure we have a project and our new project is the right one
    if (!project || !isActiveProject || !_.isEqual(parseInt(match.params.projectId), project.id)) {
      return
    }
    // Reset then load in new items
    resetFeedItems()
    loadFeedItems()
    // Clear feed items on unmount
    return () => {
      resetFeedItems()
    }
  }, [project, resetFeedItems, loadFeedItems, match.params.projectId, isActiveProject])

  // Load more items from our API
  const loadMoreItems = () => {
    if (!isActiveProject) return

    loadFeedItems({
      page: {
        before: _.get(_.last(feedItems), 'id'),
      },
    })
  }

  //
  // Render Helpers
  //

  const renderProject = () => {
    return project ? (
      <div className={activeModal ? 'overflow-hidden' : null}>
        <div className="flex mx-auto py-8">
          <div className="flex-1">
            <ProjectFeed project={project} items={feedItems} loaded={isLoaded} />
            {isLoaded && !isAtEnd && (
              <div className="lg:px-0 h-auto mt-8">
                <Button onClick={loadMoreItems} text="Load More Updates" />
              </div>
            )}
          </div>
        </div>
      </div>
    ) : null
  }

  return (
    <>
      <div className="py-16 bg-gray-100">
        <Header projects={allProjects} project={project} />
        {!isActiveProject && isProjectsLoaded ? (
          <div className="px-4">
            <p className="text-gray-400">
              This project is not currently active. Please contact the project owner for more information.
            </p>
          </div>
        ) : (
          <>
            <ProjectHeader project={project}>
              {project && isAdmin(project) && (
                <Button onClick={() => setModalNewFeedItem()} color="orange" text="+ Post Update" />
              )}
            </ProjectHeader>
            <div className="py-4 max-w-6xl mx-auto px-4 xl:px-0">{renderProject()}</div>
          </>
        )}
      </div>
    </>
  )
}

export default withRouter(ProjectFeedPage)
