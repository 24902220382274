import React, { useContext, useEffect, useRef, useState } from 'react'
// Utilities
import _ from 'lodash'
import { withRouter } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
// Contexts
import ModalContext from 'contexts/ModalContext'
import FeedItemContext from 'contexts/FeedItemContext'
// Components
import BaseModal from 'components/modals/BaseModal'
import FileList from 'components/elements/FileList'
import Button from 'components/elements/Button'

const NewFeedItemModal = ({ project }) => {
  const { setModalNone } = useContext(ModalContext)
  const { create: createFeedItem } = useContext(FeedItemContext)
  // Store our status fields
  const titleRef = useRef()
  const descriptionRef = useRef()
  const [files, setFiles] = useState([])
  const [canAttachFiles, setCanAttachFiles] = useState(false)
  // Store some things that will update the UI
  const [formErrors, setFormErrors] = useState({})
  const [submittingForm, setSubmittingForm] = useState(false)
  // Setup our dropzone
  const { getRootProps, getInputProps } = useDropzone({
    accept: '.xlsx, .xls, image/*, .doc, .docx, .ppt, .pptx, .txt, .pdf',
    onDrop: acceptedFiles => {
      // Add any newly selected files to our file list
      const newFiles = _.uniqBy([...files, ...acceptedFiles], file => file.name)
      setFiles(newFiles)
    },
  })
  // Make sure to revoke the data uris to avoid memory leaks
  useEffect(() => {
    files.forEach(file => URL.revokeObjectURL(file.preview))
  }, [files])

  //
  // Form Validations
  //

  const validateForm = () => {
    return new Promise((resolve, reject) => {
      const errors = []

      // Make sure we actually validate :)
      if (_.isEmpty(titleRef.current.value)) {
        // Invalid
        errors.push({
          parameter: 'title',
          message: 'Please enter a valid title',
        })
      }

      // Resolve or reject based on errors
      _.isEmpty(errors) ? resolve() : reject(errors)
    })
  }

  const renderError = (type, classes = '') => {
    // Grab the error from our errors object
    const error = formErrors[type]
    const classNames = `py-2 px-4 mt-2 bg-red-400 text-white text-left text-sm rounded ${classes}`

    return error ? <div className={classNames}>{error.message}</div> : null
  }

  //
  // Event Handlers
  //

  const onSubmit = event => {
    event.preventDefault()
    // Validate our form
    validateForm()
      .then(() => {
        // We're good to submit!
        setFormErrors({})
        setSubmittingForm(true)
        // We're creating a new project
        // Send the ajax request
        createFeedItem({
          title: titleRef.current.value,
          description: descriptionRef.current.value,
          files: files,
        })
          .then(() => {
            setModalNone()
          })
          .catch(error => {
            // Store the form errors we got back
            setFormErrors(_.keyBy(error.response.data, 'parameter'))
            setSubmittingForm(false)
          })
      })
      .catch(errors => {
        // We are not ready to submit yet
        setFormErrors(_.keyBy(errors, 'parameter'))
        setSubmittingForm(false)
      })
  }

  const onRemoveFile = file => {
    setFiles(files.filter(existingFile => existingFile.name !== file.name))
  }

  const onClickAddAttachments = event => {
    event.preventDefault()
    setCanAttachFiles(true)
  }

  return (
    <BaseModal
      title="Post a Status Update"
      submitProps={{
        text: submittingForm ? 'Posting...' : 'Post Update',
        size: 'large',
        color: 'orange',
        extraClasses: 'mr-4',
      }}
      isSubmitting={submittingForm}
      onSubmit={onSubmit}
    >
      <label className="block mb-2 text-sm font-bold">Title*:</label>
      <input
        ref={titleRef}
        className="w-full p-4 border shadow-inner rounded bg-gray-100 focus:bg-white outline-none focus:shadow-outline"
        placeholder="Enter a title for your update"
        disabled={submittingForm}
      />
      {renderError('title')}
      <label className="block mb-2 mt-4 text-sm font-bold">Description:</label>
      <textarea
        ref={descriptionRef}
        className="w-full p-4 h-24 border shadow-inner rounded bg-gray-100 focus:bg-white outline-none focus:shadow-outline"
        placeholder="What happened?"
        disabled={submittingForm}
      />
      {canAttachFiles && (
        <>
          <div
            {...getRootProps()}
            className="flex items-center justify-center mt-4 h-24 w-full border-2 border-dashed text-gray-600 rounded cursor-pointer hover:border-orange-400 hover:bg-orange-100 hover:text-orange-600"
          >
            <input {...getInputProps()} />
            <p className="text-sm">Drag 'n' drop some files here, or click to select files</p>
          </div>
          <div className="flex flex-col pt-4">
            <FileList files={files} onRemove={onRemoveFile} />
          </div>
        </>
      )}
      {!canAttachFiles && <Button text="+ Add Attachments" color="none" onClick={onClickAddAttachments} />}
    </BaseModal>
  )
}

export default withRouter(NewFeedItemModal)
