//
// This component is used to create a group of tags and is currently
// used for emails only. When using this again... Update it so you can pass
// the validation function into the component itself
//

import React, { useEffect, useRef, useState } from 'react'
import usePrevious from 'utils/hooks/usePrevious'
// Utilities
import validator from 'validator'

const FieldMultiTag = ({ onChange, placeholder }) => {
  const fieldRef = useRef()
  const [tags, setTags] = useState([])
  const prevTags = usePrevious(tags)

  // On change of our tags, fire onChange
  useEffect(() => {
    if (prevTags === tags) {
      return
    }
    // Fire our change event
    onChange && onChange(tags)
  }, [tags, prevTags, onChange])

  // Validate an email address
  const validate = text => {
    return validator.isEmail(text)
  }

  // Update the tags in our list
  const updateTags = () => {
    // This keypress was meant to add the tag (if it validates)
    if (validate(fieldRef.current.value)) {
      setTags(prevTags => {
        const newTag = fieldRef.current.value.toLowerCase()
        // Don't add this new tag if it already exists!
        return prevTags.indexOf(newTag) === -1 ? [...prevTags, newTag] : prevTags
      })
      // Clear the field
      fieldRef.current.value = ''
    }
  }

  // On blur, either clear the field or add the current email
  const onBlur = () => {
    if (validate(fieldRef.current.value)) {
      updateTags()
    } else {
      fieldRef.current.value = ''
    }
  }

  // On keydown, if it's a "sendKey" add the tag if it's valid
  const onKeyDown = event => {
    const sendKeys = [32, 13, 9] // [Space, Enter, Tab]

    if (sendKeys.indexOf(event.keyCode) !== -1) {
      updateTags()
      // Stop from firing the onChange event (unless it's a tab press)
      if (event.keyCode !== 9) event.preventDefault()
    }
  }

  const removeTag = tag => {
    setTags(prevTags => prevTags.filter(prev => prev !== tag))
  }

  const renderTag = tag => {
    return (
      <div
        className="flex items-center justify-center h-8 pl-4 ml-2 mt-2 bg-orange-500 border border-orange-600 text-white text-sm font-bold rounded shadow"
        key={tag}
      >
        <span>{tag}</span>
        <span
          onClick={() => removeTag(tag)}
          className="flex items-center justify-center ml-4 h-8 w-8 border-l border-orange-600 cursor-pointer hover:bg-white-translucent rounded-tr rounded-br"
        >
          &times;
        </span>
      </div>
    )
  }

  return (
    <div>
      <div className="flex flex-wrap pb-2 w-full border shadow-inner rounded bg-gray-100 focus-within:bg-white focus-within:shadow-outline">
        {tags.map(tag => renderTag(tag))}
        <input
          ref={fieldRef}
          className="flex-1 h-8 pr-4 pl-2 ml-2 mt-2 bg-transparent outline-none"
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          placeholder={placeholder || ''}
        ></input>
      </div>
    </div>
  )
}

export default FieldMultiTag
