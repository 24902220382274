import React, { useContext, useEffect } from 'react'
// Containers
import AuthenticatedContainer from 'containers/AuthenticatedContainer'
// Contexts
import AuthContext from 'contexts/AuthContext'
// Components
import AuthenticatedRoutes from 'routes/AuthenticatedRoutes'
import NonAuthenticatedRoutes from 'routes/NonAuthenticatedRoutes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const BaseRoutes = () => {
  const { isLoaded, current: currentUser, load: loadUser } = useContext(AuthContext)

  // Load in our user on base route load
  useEffect(() => {
    loadUser()
  }, [loadUser])

  const renderLoaded = currentUser ? (
    <AuthenticatedContainer>
      <AuthenticatedRoutes />
    </AuthenticatedContainer>
  ) : (
    <NonAuthenticatedRoutes />
  )

  const renderNotLoaded = (
    <div className="flex h-screen items-center justify-center">
      <FontAwesomeIcon icon="spinner-third" size="4x" color="#ed8936" className="opacity-25" spin />
    </div>
  )

  return isLoaded ? renderLoaded : renderNotLoaded
}

export default BaseRoutes
