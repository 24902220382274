//
// Helper to setup the icons we're going to be able to use
// - To add a new one, add it to the top import command and then
//   again to the `library.add` method.
//

import { library } from '@fortawesome/fontawesome-svg-core'
// Icons
import {
  faBars,
  faCaretDown,
  faDownload,
  faEllipsisH,
  faExpand,
  faFile,
  faFileCsv,
  faFileExcel,
  faFileImage,
  faFileVideo,
  faFileWord,
  faPlus,
  faSpinner,
  faStore,
  faTimes,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import { faGlassCheers, faSpinnerThird } from '@fortawesome/pro-solid-svg-icons'

// Add our icons to the library so we can reuse them in all components
export const setupFontAwesome = () => {
  library.add(
    faExpand,
    faSpinner,
    faSpinnerThird,
    faGlassCheers,
    faFileWord,
    faFileVideo,
    faFileImage,
    faFileExcel,
    faFileCsv,
    faFile,
    faDownload,
    faTimes,
    faStore,
    faUser,
    faPlus,
    faBars,
    faEllipsisH,
    faCaretDown
  )
}
