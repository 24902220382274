import React, { useContext, useRef, useState } from 'react'
// Utilities
import { NavLink, withRouter } from 'react-router-dom'
import _ from 'lodash'
// Contexts
import AuthContext from 'contexts/AuthContext'
// Components
import Button from 'components/elements/Button'

const LoginPage = props => {
  const { login: loginUser } = useContext(AuthContext)
  const emailRef = useRef()
  const passwordRef = useRef()
  const [formErrors, setFormErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  //
  // Render Methods
  //

  const renderError = (type, classes = '') => {
    // Grab the error from our errors object
    const error = (formErrors || {})[type]
    const classNames = `py-2 px-4 mt-2 bg-red-400 text-white text-left text-sm rounded ${classes}`

    return error ? <div className={classNames}>{error.message}</div> : null
  }

  //
  // Event Handlers
  //

  const onLogin = event => {
    // Parse our location search for any params
    const inviteParams = {}
    const queryString = require('query-string')
    const query = queryString.parse(props.location.search)
    // Add our token if we have one
    if (query.invite) {
      inviteParams['invite_token'] = query.invite
    }
    setIsLoading(true)
    loginUser(
      {
        user: {
          email: emailRef.current.value,
          password: passwordRef.current.value,
        },
      },
      inviteParams
    )
      .then(() => {
        // After login, route to the referrer OR the base page
        const from = _.get(props.location, 'state.from') || '/projects'
        props.history.push(from)
        setIsLoading(false)
      })
      .catch(error => {
        // Process & store the errors
        setFormErrors(_.keyBy(error.response.data, err => _.camelCase(err.parameter)))
        setIsLoading(false)
      })

    // Make sure we use ajax here
    event.preventDefault()
    return false
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen py-6 overflow-scroll">
      <div className="w-full px-4">
        <div className="mx-auto max-w-lg text-center">
          <NavLink
            className="flex justify-center h-12 mb-8 px-1 items-center hover:bg-white-translucent rounded"
            to="/"
          >
            <img
              className="h-10 w-10 shadow rounded-xl"
              src={require('assets/images/icon-160.png')}
              alt="CreatorLoop.com"
            />
            <div className="mx-3 align-top text-2xl font-montserrat text-gray-800">
              <strong>Creator</strong>Loop
            </div>
          </NavLink>
          {renderError('session', 'mb-4')}
          <form onSubmit={event => onLogin(event)}>
            <input
              ref={emailRef}
              className="w-full p-4 border shadow-inner rounded bg-white focus:bg-white outline-none focus:shadow-outline"
              placeholder="Email"
            />
            {renderError('email')}
            <input
              ref={passwordRef}
              type="password"
              className="w-full mt-4 p-4 border shadow-inner rounded bg-white focus:bg-white outline-none focus:shadow-outline"
              placeholder="Password"
            />
            {renderError('password')}
            <Button
              text={isLoading ? 'Logging In...' : 'Login'}
              size="large"
              type="submit"
              color="orange"
              extraClasses="w-full mt-8"
              disabled={isLoading}
            />
          </form>
          <div className="mt-4 text-center">
            <NavLink
              to={{ pathname: '/join', search: props.location.search }}
              className="block p-2 mx-auto max-w-x text-gray-600 text-sm"
            >
              Don't have an account? <strong>Sign Up Today!</strong>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(LoginPage)
