import React, { useContext, useState } from 'react'
// Utilities
import _ from 'lodash'
import { withRouter } from 'react-router-dom'
// Contexts
import ModalContext from 'contexts/ModalContext'
import ProjectContext from 'contexts/ProjectContext'
// Components
import BaseModal from 'components/modals/BaseModal'

const DeleteProjectModal = ({ project, history, match }) => {
  const { setModalNone } = useContext(ModalContext)
  const { destroy: deleteProject } = useContext(ProjectContext)
  const [name, setName] = useState('')
  const [formErrors, setFormErrors] = useState({})
  const [submittingForm, setSubmittingForm] = useState(false)

  //
  // Form Validations
  //

  const validateForm = () => {
    return new Promise((resolve, reject) => {
      const errors = []

      // Make sure we actually validate :)
      if (_.isEmpty(name)) {
        // Invalid
        errors.push({
          parameter: 'name',
          message: `Please enter the name of your project: ${project.name}`,
        })
      }

      // Resolve or reject based on errors
      _.isEmpty(errors) ? resolve() : reject(errors)
    })
  }

  const renderError = (type, classes = '') => {
    // Grab the error from our errors object
    const error = formErrors[type]
    const classNames = `py-2 px-4 mt-2 bg-red-400 text-white text-left text-sm rounded ${classes}`

    return error ? <div className={classNames}>{error.message}</div> : null
  }

  //
  // Event Handlers
  //

  const onSubmit = event => {
    event.preventDefault()
    event.persist()
    // Validate our form
    validateForm()
      .then(() => {
        // We're good to submit!
        setFormErrors({})
        setSubmittingForm(true)
        // Store our current id
        const projectId = project.id
        // Send the ajax request
        deleteProject(projectId, name)
          .then(response => {
            // Force-redirect us to the projects page if we are on the now-deleted project
            if (_.get(match, 'params.projectId') === projectId) {
              history.replace('/projects')
              setModalNone()
            } else {
              setModalNone()
            }
          })
          .catch(error => {
            // Store the form errors we got back
            setFormErrors(_.keyBy(error.response.data, 'parameter'))
            setSubmittingForm(false)
          })
      })
      .catch(errors => {
        // We are not ready to submit yet
        setFormErrors(_.keyBy(errors, 'parameter'))
        setSubmittingForm(false)
      })
  }

  //
  // Render Constants
  //

  return (
    <BaseModal
      title={`Delete Project: ${project.name}`}
      submitProps={{
        text: submittingForm ? 'Deleting...' : 'Delete the Project',
        size: 'large',
        color: 'red',
        extraClasses: 'mr-4',
      }}
      isSubmitting={submittingForm}
      onSubmit={onSubmit}
      baseExtraClasses="text-center"
      footerExtraClasses="justify-center"
    >
      <div className="p-4 mb-8 bg-red-200 text-red-600 rounded">
        <p>
          <strong>READ THIS WARNING CAREFULLY:</strong> Deleting a project cannot be undone. The project will be removed
          and no longer accessible by any members. This includes any files, documents, or discussions. To delete it
          anyways, please enter the name <strong>{project.name}</strong> below.
        </p>
      </div>
      <label className="block mb-2 text-sm font-bold">Enter the name of the project you are deleting:</label>
      <input
        className="w-full p-4 border shadow-inner rounded bg-red-100 border-red-200 focus:bg-white outline-none focus:shadow-outline text-center font-bold"
        onChange={event => setName(event.target.value)}
        placeholder={`Project Name (${project.name})`}
        disabled={submittingForm}
      />
      {renderError('name')}
      <p className="p-4 italic text-sm text-gray-600">
        We only do this to make sure you really want to delete your project and are not pressing this accidentally :)
      </p>
    </BaseModal>
  )
}

export default withRouter(DeleteProjectModal)
