import React from 'react'
// Utilities
import { BrowserRouter as Router } from 'react-router-dom'
import BaseRoutes from 'routes/BaseRoutes'
import { setupFontAwesome } from 'utils/helpers/fontawesome'
// Containers
import BaseContainer from 'containers/BaseContainer'
// Styles
import 'assets/styles/generated/index.css'

// Setup our svg's for icons
setupFontAwesome()

const App = () => {
  return (
    <BaseContainer>
      <Router>
        <BaseRoutes />
      </Router>
    </BaseContainer>
  )
}

export default App
