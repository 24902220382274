import React, { useContext, useState } from 'react'
// Utilities
import { NavLink, withRouter } from 'react-router-dom'
import _ from 'lodash'
import clsx from 'clsx'
// Contexts
import AuthContext from 'contexts/AuthContext'
// Components
import Dropdown from 'components/elements/Dropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Header = ({ projects, project, title, history, match }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const { current: currentUser, logout: logoutUser } = useContext(AuthContext)

  const projectsData = !_.isEmpty(projects)
    ? projects.map(n => ({
        id: n.id,
        text: n.name,
        to: `/projects/${n.id}`,
      }))
    : null

  // Methods

  const onClickAccount = () => {
    setMenuOpen(false)
    history.replace('/account')
  }

  const onClickLogout = event => {
    setMenuOpen(false)
    logoutUser().then(() => {
      history.replace('/login')
    })
    event.preventDefault()
    event.stopPropagation()
  }

  return (
    <>
      <header className="fixed top-0 left-0 right-0 h-16 border-b border-gray-400 bg-white shadow z-50">
        <div className="hidden px-4 flex flex-row md:flex">
          <div className="flex flex-1 items-center">
            <NavLink className="flex h-12 my-2 mr-3 px-1 items-center" to="/projects">
              <img
                className="h-10 w-10 shadow rounded-xl"
                src={require('assets/images/icon-160.png')}
                alt="CreatorLoop.com"
              />
              <div className="mx-3 align-top text-2xl font-montserrat text-gray-900">
                <strong>Creator</strong>Loop
              </div>
            </NavLink>
            {project && (
              <>
                <Dropdown
                  align="left"
                  options={projectsData}
                  selected={project.id}
                  buttonProps={{
                    color: 'gray',
                    prependIcon: 'caret-down',
                  }}
                  selectable={true}
                />
              </>
            )}
          </div>

          {currentUser && (
            <div className="flex flex-initial items-center">
              <Dropdown
                buttonProps={{ text: currentUser.email, color: 'none' }}
                options={[
                  {
                    id: 'account',
                    text: 'Your Account',
                    onClick: onClickAccount,
                  },
                  {
                    id: 'logout',
                    text: 'Logout',
                    onClick: onClickLogout,
                    extraClasses: 'text-red-500',
                  },
                ]}
              />
            </div>
          )}
        </div>

        <div className="flex items-center justify-center h-full p-4 md:hidden">
          <div className="h-10 w-10">
            {!menuOpen && (
              <div
                className="flex items-center justify-center h-10 w-10 text-gray-900 rounded"
                onClick={() => setMenuOpen(true)}
              >
                <FontAwesomeIcon icon="bars" size="lg" />
              </div>
            )}
          </div>
          <div className="flex-1 flex justify-center">
            <NavLink className="h-10 w-10" to="/projects">
              <img
                className="h-10 w-10 shadow rounded-xl"
                src={require('assets/images/icon-160.png')}
                alt="CreatorLoop.com"
              />
            </NavLink>
          </div>
          <div className="h-10 w-10">
            {menuOpen && (
              <div
                className="flex items-center justify-center h-10 w-10 text-gray-900 rounded"
                onClick={() => setMenuOpen(false)}
              >
                <FontAwesomeIcon icon="times" size="lg" />
              </div>
            )}
          </div>
        </div>
      </header>
      {menuOpen && (
        <div className="fixed top-0 right-0 bottom-0 left-0 w-full h-full p-4 pt-20 bg-white z-40">
          <div className={clsx('py-4')}>
            <NavLink className="block py-4 font-bold rounded" to="/projects">
              Projects
            </NavLink>
            <NavLink className="block py-4 border-t border-gray-200 font-bold rounded" to="/account">
              Account
            </NavLink>
            <a
              className="block py-4 border-t border-gray-200 font-bold text-red-500 rounded"
              href="/"
              onClick={onClickLogout}
            >
              Logout
            </a>
          </div>
        </div>
      )}
    </>
  )
}

export default withRouter(Header)
