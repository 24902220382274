import React from 'react'
// Components
import ProjectFeedItem from 'components/ProjectFeedItem'

const ProjectFeedGroup = ({ project, items }) => {
  // Render a group of items (or Empty)
  const renderItems = () => {
    return items && items.length > 0 ? (
      <div className="mt-4 first-child:mt-0">
        {items.map(item => (
          <ProjectFeedItem key={item.id} project={project} item={item} />
        ))}
      </div>
    ) : (
      renderEmpty()
    )
  }

  // Render an empty item
  const renderEmpty = () => {
    return (
      <div className="py-8 px-2 bg-gray-100 shadow-inner text-center rounded">
        <p className="text-gray-500 italic">No updates</p>
      </div>
    )
  }

  return <>{renderItems()}</>
}

export default ProjectFeedGroup
