import React, { useCallback, useContext, useState } from 'react'
// Utilities
import _ from 'lodash'
import { withRouter } from 'react-router-dom'
// Contexts
import ModalContext from 'contexts/ModalContext'
import ProjectContext from 'contexts/ProjectContext'
// Components
import BaseModal from 'components/modals/BaseModal'
import FieldUpload from 'components/elements/FieldUpload'

const EditProjectModal = ({ project }) => {
  const { setModalNone } = useContext(ModalContext)
  const { update: updateProject } = useContext(ProjectContext)
  const [name, setName] = useState(project.name)
  const [description, setDescription] = useState(project.description)
  const [icon, setIcon] = useState(project.icon)
  const [formErrors, setFormErrors] = useState({})
  const [submittingForm, setSubmittingForm] = useState(false)

  const onUpdateIcon = useCallback(file => {
    console.log({ onUpdateIcon: file })
    setIcon(file)
  }, [])

  //
  // Form Validations
  //

  const validateForm = () => {
    return new Promise((resolve, reject) => {
      const errors = []

      // Make sure we actually validate :)
      if (_.isEmpty(name)) {
        // Invalid
        errors.push({
          parameter: 'name',
          message: 'Please enter a valid name',
        })
      }

      // Resolve or reject based on errors
      _.isEmpty(errors) ? resolve() : reject(errors)
    })
  }

  const renderError = (type, classes = '') => {
    // Grab the error from our errors object
    const error = formErrors[type]
    const classNames = `py-2 px-4 mt-2 bg-red-400 text-white text-left text-sm rounded ${classes}`

    return error ? <div className={classNames}>{error.message}</div> : null
  }

  //
  // Event Handlers
  //

  const onSubmit = event => {
    event.preventDefault()
    // Validate our form
    validateForm()
      .then(() => {
        // We're good to submit!
        setFormErrors({})
        setSubmittingForm(true)

        // We're editing a project
        // Send the ajax request
        updateProject(project.id, {
          name: name,
          description: description,
          icon: icon,
          removeIcon: project.icon && !icon,
        })
          .then(() => {
            // When we've updated it, regardless of where we are just close the modal
            setModalNone()
          })
          .catch(error => {
            // Store the form errors we got back
            setFormErrors(_.keyBy(error.response.data, 'parameter'))
            setSubmittingForm(false)
          })
      })
      .catch(errors => {
        // We are not ready to submit yet
        setFormErrors(_.keyBy(errors, 'parameter'))
        setSubmittingForm(false)
      })
  }

  return (
    <BaseModal
      title={'Edit Project'}
      submitProps={{
        text: submittingForm ? 'Updating...' : 'Update Project',
        size: 'large',
        color: 'orange',
        extraClasses: 'mr-4',
      }}
      isSubmitting={submittingForm}
      onSubmit={onSubmit}
    >
      <label className="block mb-2 text-sm font-bold">Project Name*:</label>
      <input
        className="w-full p-4 border shadow-inner rounded bg-gray-100 focus:bg-white outline-none focus:shadow-outline"
        onChange={event => setName(event.target.value)}
        value={name}
        placeholder="Enter a name for your project"
        disabled={submittingForm}
      />
      {renderError('name')}
      <label className="block mb-2 mt-4 text-sm font-bold">Description:</label>
      <textarea
        className="w-full p-4 h-24 border shadow-inner rounded bg-gray-100 focus:bg-white outline-none focus:shadow-outline"
        onChange={event => setDescription(event.target.value)}
        value={description}
        placeholder="How would you describe this project?"
        disabled={submittingForm}
      />
      <label className="block mt-3 text-sm font-bold">Project Icon:</label>
      <FieldUpload
        onChange={onUpdateIcon}
        placeholder={"Drag'n drop your project icon here, or click to select"}
        initialFile={project.icon}
      />
    </BaseModal>
  )
}

export default withRouter(EditProjectModal)
