import React, { useContext, useEffect, useState } from 'react'
// Utilities
import { withRouter } from 'react-router-dom'
import clsx from 'clsx'
// Contexts
import ModalContext from 'contexts/ModalContext'
// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const FileModal = ({ file }) => {
  const { setModalNone } = useContext(ModalContext)
  const [expanded, setExpanded] = useState(false)

  // When it's opened, set a body class so we don't scroll the body
  useEffect(() => {
    document.body.classList.add('overflow-hidden')
    return () => {
      document.body.classList.remove('overflow-hidden')
    }
  }, [])

  const onClickClose = event => {
    event.stopPropagation()
    setModalNone()
    return false
  }

  const toggleExpanded = event => {
    event.stopPropagation()
    setExpanded(!expanded)
    return false
  }

  // Render helpers

  const renderImage = (
    <div
      className={clsx(
        'flex py-16 h-full w-full overflow-scroll',
        expanded ? 'items-start justify-start' : 'items-center justify-center'
      )}
    >
      <img
        className={clsx('m-auto', expanded ? 'max-w-none' : 'max-w-xl')}
        src={expanded ? file.sizes.large.url : file.sizes.medium.url}
        alt={file.meta.name}
        onClick={toggleExpanded}
      />
    </div>
  )

  return (
    <div className="fixed flex z-50 top-0 left-0 bottom-0 right-0 bg-overlay-black" onClick={onClickClose}>
      <div className="fixed px-6 top-0 left-0 right-0 flex items-center h-16 bg-overlay-black">
        <div className="flex-1 text-white text-lg font-bold">{file.meta.name}</div>
        <div className="flex justify-center">
          <button className="pl-4 text-white" onClick={toggleExpanded}>
            <FontAwesomeIcon icon="expand" size="lg" />
          </button>
        </div>
      </div>
      {file.meta.type === 'image' && renderImage}
    </div>
  )
}

export default withRouter(FileModal)
