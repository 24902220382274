import React, { useContext, useEffect } from 'react'
import clsx from 'clsx'
// Contexts
import ModalContext from 'contexts/ModalContext'
// Components
import Button from 'components/elements/Button'

const BaseModal = props => {
  const { setModalNone } = useContext(ModalContext)

  // When it's opened, set a body class so we don't scroll the body
  useEffect(() => {
    document.body.classList.add('overflow-hidden')
    return () => {
      document.body.classList.remove('overflow-hidden')
    }
  }, [])

  const onClickClose = event => {
    event.stopPropagation()
    setModalNone()
  }

  const submitButtonProps = props.submitProps || {}

  const cancelButtonProps = {
    size: 'large',
    text: 'Cancel',
    color: 'gray',
    ...(props.cancelProps || {}),
  }

  const submitDisabled = submitButtonProps.disabled !== undefined ? submitButtonProps.disabled : props.isSubmitting
  const cancelDisabled = cancelButtonProps.disabled !== undefined ? cancelButtonProps.disabled : props.isSubmitting

  return (
    <div
      className={`fixed flex z-50 top-0 left-0 bottom-0 right-0 bg-overlay-black ${props.baseExtraClasses}`}
      onClick={onClickClose}
    >
      <div className="w-full flex-col h-full md:p-8 flex items-center justify-center">
        <div
          className={clsx(
            'w-full bg-white h-screen md:h-auto md:rounded',
            props.width === 'full' ? 'max-w-4xl' : 'max-w-xl'
          )}
          onClick={event => event.stopPropagation()}
        >
          <form className="flex flex-col w-full h-screen md:h-auto" onSubmit={props.onSubmit}>
            {props.title ? (
              <div className="flex items-center p-4 border-b border-gray-200">
                <div className="flex-1 text-black font-bold text-xl text-center md:text-left">
                  <p>{props.title}</p>
                </div>
              </div>
            ) : null}

            <div
              className={clsx(
                'flex-1 w-full p-4 max-h-screen-modal overflow-hidden overflow-y-scroll',
                props.bodyExtraClasses
              )}
            >
              {props.children}
            </div>

            <div
              className={`flex justify-start items-center p-4 border-t border-gray-200 ${props.footerExtraClasses} flex-col md:flex-row`}
            >
              <Button
                {...submitButtonProps}
                extraClasses="flex-1 w-full py-2 md:py-0 md:flex-none md:w-auto md:mr-2"
                onClick={props.onSubmit}
                disabled={submitDisabled}
              />
              <Button
                {...cancelButtonProps}
                extraClasses="flex-1 w-full py-2 mt-2 md:mt-0 md:py-0 md:flex-none md:w-auto md:mr-2"
                onClick={onClickClose}
                disabled={cancelDisabled}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default BaseModal
