// Utilities
import baseAxios from './base'

const UserApi = {
  // Get all of a project's users
  list: (projectId, params) => {
    return baseAxios.get(`/projects/${projectId}/users`, { params })
  },

  // Create a new user (add or invite)
  create: (projectId, params) => {
    return baseAxios.post(`/projects/${projectId}/users`, params)
  },

  // Update a project user
  update: (projectId, user_id, params) => {
    return baseAxios.put(`/projects/${projectId}/users/${user_id}`, params)
  },

  // Delete a project user
  delete: (projectId, user_id) => {
    return baseAxios.delete(`/projects/${projectId}/users/${user_id}`)
  },

  // Uninvite (for when a user hasn't accepted an invitation yet)
  uninvite: (projectId, invite_id) => {
    return baseAxios.delete(`/projects/${projectId}/users/${invite_id}/uninvite`)
  },
}

export default UserApi
