import React, { useContext, useRef, useState } from 'react'
// Utilities
import { NavLink, withRouter } from 'react-router-dom'
import _ from 'lodash'
// Contexts
import AuthContext from 'contexts/AuthContext'
// Components
import Button from 'components/elements/Button'
import AvatarUpload from 'components/elements/AvatarUpload'
import Dropdown from 'components/elements/Dropdown'

const SignupPage = props => {
  const { signup: signupUser } = useContext(AuthContext)
  const [icon, setIcon] = useState(null)
  const [joiningAs, setJoiningAs] = useState('company')
  const nameRef = useRef()
  const emailRef = useRef()
  const passwordRef = useRef()
  const [formErrors, setFormErrors] = useState({})

  const onAvatarChange = file => {
    setIcon(file)
  }

  //
  // Render Methods
  //

  const renderError = (type, classes = '') => {
    // Grab the error from our errors object
    const error = formErrors[type]
    const classNames = `py-2 px-4 mt-2 bg-red-400 text-white text-left text-sm rounded ${classes}`

    return error ? <div className={classNames}>{error.message}</div> : null
  }

  //
  // Event Handlers
  //

  const onSignup = event => {
    // Parse our location search for any params
    const inviteParams = {}
    const queryString = require('query-string')
    const query = queryString.parse(props.location.search)
    // Add our token if we have one
    if (query.invite) {
      inviteParams['invite_token'] = query.invite
    }
    // Sign the user up
    signupUser(
      {
        icon: icon,
        name: nameRef.current.value,
        email: emailRef.current.value,
        password: passwordRef.current.value,
        signed_up_as: joiningAs,
      },
      inviteParams
    )
      .then(() => {
        // After signup route to the projects page (or where they came from)
        const from = _.get(props.location, 'state.from') || '/projects'
        props.history.push(from)
      })
      .catch(error => {
        // Process & store the errors
        setFormErrors(_.keyBy(error.response.data, err => _.camelCase(err.parameter)))
      })

    // Make sure we use ajax here
    event.preventDefault()
    return false
  }

  // Add delete option
  const signupOptions = [
    {
      id: 'company',
      text: "I'm joining as a company",
      icon: 'store',
      onClick: () => setJoiningAs('company'),
    },
    {
      id: 'individual',
      text: "I'm joining as an individual",
      icon: 'user',
      onClick: () => setJoiningAs('individual'),
    },
  ]

  return (
    <div className="flex flex-col items-center justify-center min-h-screen py-6 overflow-scroll">
      <div className="w-full px-4">
        <div className="mx-auto max-w-lg text-center">
          {renderError('session', 'mb-4')}
          <form onSubmit={event => onSignup(event)}>
            <AvatarUpload
              extraClasses="m-auto"
              onChange={onAvatarChange}
              placeholderIcon={joiningAs === 'company' ? 'store' : 'user'}
            />
            <div className="pb-2">
              <Dropdown
                buttonProps={{ position: 'left', extraClasses: 'mb-2 w-full' }}
                align="right"
                options={signupOptions}
                selected={'company'}
                selectable
              />
            </div>
            <input
              ref={nameRef}
              className="w-full p-4 border border-gray-400 shadow-inner rounded bg-white focus:bg-white outline-none focus:shadow-outline-orange"
              placeholder={joiningAs === 'company' ? 'Company Name' : 'Your Name'}
            />
            {renderError('name')}
            <input
              ref={emailRef}
              className="w-full mt-4 p-4 border border-gray-400 shadow-inner rounded bg-white focus:bg-white outline-none focus:shadow-outline-orange"
              placeholder="Email"
            />
            {renderError('email')}
            <input
              ref={passwordRef}
              type="password"
              className="w-full mt-4 p-4 border border-gray-400 shadow-inner rounded bg-white focus:bg-white outline-none focus:shadow-outline-orange"
              placeholder="Password"
            />
            {renderError('password')}
            <Button text="Join Today" size="large" color="orange" extraClasses="w-full mt-4" type="submit" />
          </form>
          <div className="mt-4 text-center">
            <NavLink
              to={{ pathname: '/login', search: props.location.search }}
              className="block p-2 mx-auto max-w-x text-gray-600 text-sm"
            >
              Already a member? <strong>Login</strong>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(SignupPage)
