import React, { createContext, useCallback, useState } from 'react'
// Utilities
import AuthApi from 'utils/api/auth'
import userRoles from 'utils/constants/user-roles'

const AuthContext = createContext({
  // Data
  current: null,
  billingInfo: null,
  // State
  isLoaded: false,
  // Functions
  login: () => {},
  signup: () => {},
  update: () => {},
  logout: () => {},
  load: () => {},
  billing: () => {},
  subscribe: () => {},
  addCard: () => {},
  hasFeature: () => {},
})

export const AuthProvider = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [current, setCurrent] = useState(null)
  const [billingInfo, setBillingInfo] = useState(null)

  // Log in our user
  const login = useCallback((params = {}, opts = {}) => {
    return AuthApi.loginUser(Object.assign({}, params, opts)).then(response => {
      // Store our auth token
      const authToken = response.headers.authorization.replace('Bearer ', '')
      localStorage.setItem('cltkn', authToken)
      // Set our current user
      setCurrent(response.data)
      setIsLoaded(true)
      return response.data
    })
  }, [])

  // Sign up a new user
  const signup = useCallback((params = {}, opts = {}) => {
    return AuthApi.signupUser(Object.assign({}, params, opts)).then(response => {
      // Store our auth token
      const authToken = response.headers.authorization.replace('Bearer ', '')
      localStorage.setItem('cltkn', authToken)
      // Set our current user
      setCurrent(response.data)
      setIsLoaded(true)
      return response.data
    })
  }, [])

  // Update the current user
  const update = useCallback((params = {}, opts = {}) => {
    return AuthApi.updateUser(Object.assign({}, params, opts)).then(response => {
      // Set our current user
      setCurrent(response.data)
      setIsLoaded(true)
      return response.data
    })
  }, [])

  // Logout our current user
  const logout = useCallback(() => {
    return new Promise(resolve => {
      // Remove our auth token
      localStorage.removeItem('cltkn')
      // Set our current user
      setCurrent(null)
      setBillingInfo(null)
      setIsLoaded(true)
      resolve()
    })
  }, [])

  // Load our user's billing info
  const billing = useCallback((params = {}) => {
    return AuthApi.billing().then(response => {
      // Set our current user
      setBillingInfo(response.data)
      return response.data
    })
  }, [])

  // Subscribe to a new plan
  const subscribe = useCallback((params = {}) => {
    return AuthApi.subscribe(params).then(response => {
      setCurrent(prevVal => ({
        ...prevVal,
        plan: response.data.plan,
      }))
      // Set our billing info
      setBillingInfo(prevVal => ({
        ...prevVal,
        subscription: response.data.subscription,
      }))
      return response.data
    })
  }, [])

  // Add a card to a user
  const addCard = useCallback((params = {}) => {
    return AuthApi.addCard(params).then(response => {
      // Set our billing info
      setBillingInfo(prevVal => ({
        ...prevVal,
        cards: response.data.cards,
      }))
      return response.data.card
    })
  }, [])

  // Load our existing user
  const load = useCallback(() => {
    return AuthApi.loadUser()
      .then(response => {
        // Set our current user
        setCurrent(response.data)
        setIsLoaded(true)
        return response.data
      })
      .catch(() => {
        setIsLoaded(true)
      })
  }, [])

  // See if a user has a certain role
  const hasFeature = useCallback(
    feature => {
      if (!current) {
        return
      }
      // See if this user's role includes a certain feature
      return userRoles[current.role].features.indexOf(feature) !== -1
    },
    [current]
  )

  return (
    <AuthContext.Provider
      value={{
        // Data
        current,
        billingInfo,
        isLoaded,
        // Functions
        login,
        signup,
        update,
        logout,
        load,
        billing,
        subscribe,
        addCard,
        hasFeature,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext
